import Link from 'next/link';
import { FC, useEffect, useMemo, useState } from 'react';
import { useGtmTracking } from '../../gtm-tracking/hooks/use-gtm-tracking';
import { umbraco } from '../../lib/api';
import { MegaMenuSettings, SearchSettings, orgTypes } from '../../lib/api/models/umbraco';
import { MiniBasketStore } from '../../lib/state/hessel-site/mini-basket';
import { cmsUrlWithoutSiteId } from '../../utils/helpers';
import { SidePanel } from '../shared/modals/side-panel';
import { SvgIcon } from '../shared/svg-icon';
import { ToggleUi } from '../shared/toggle-ui';
import { BurgerMenuDesktop } from './burger-menu-desktop/burger-menu-desktop.component';
import {
    StyledBurgerItem,
    StyledCenteredDeviceBlock,
    StyledCenteredNonDeviceBlock,
    StyledExtraOptions,
    StyledHeader,
    StyledMegaMenuLogo,
    StyledMobileNav,
    StyledNav,
    StyledNavWrapper,
    StyledTextLogo,
    StyledToggleWrapper,
    StyledCartLink,
    StyledCartOrderlineCountIndicator,
    StyledHireLeadLink,
    StyledLink,
    UtilityLink,
    StyledCartIcons,
} from './mega-menu.styled';
import { TopLevelItem } from './top-level-item/top-level-item.component';
import Image from 'next/image';
import { MEDIA_URL } from '../../utils/environment-constants';
import { useRouter } from 'next/router';
import { BurgerMenuMobile } from './burger-menu-mobile/burger-menu-mobile.component';
import { ContactSection } from '../../lib/api/models/umbraco/mega-menu.types';
import { useScrollLock } from '../../hooks/use-scroll-lock';
import { useMegaMenu } from './use-mega-menu';
import { useMediaQuery } from '../../hooks/use-media-query';
import { CartStoreContext } from '../../lib/state/shop/cart/cart.store';
import { MiniBasketModal } from './mini-basket-modal/mini-basket-modal.component';
import { lead_checkout_url } from '../../constants/site-consts';
import { SearchOverlay } from '../search-overlay/search-overlay.component';
import { useSearchOverlay } from '../../hooks/use-search-overlay';
import { SearchStoreContext } from '../../lib/state/search/search.store';

type IProps = {
    megaMenuSettings: MegaMenuSettings;
    miniBasketSettings: umbraco.HireMiniBasketSettings;
    dealershipSettings: orgTypes.DealershipListSettings;
    searchSettings: umbraco.SearchSettings | undefined;
    menuContext: MenuContext;
    pageId: string;
    onMegaMenuChange: (newState: boolean) => void;
};

export type MenuContext = 'privat' | 'erhverv';
const LOGO_TEXT = 'EJNER HESSEL';

export const MegaMenu: FC<IProps> = ({
    megaMenuSettings,
    miniBasketSettings,
    dealershipSettings,
    menuContext,
    pageId,
    onMegaMenuChange,
    searchSettings,
}) => {
    const [burgerOpen, setBurgerOpen] = useState(false);
    const [showMiniBasket, setShowMiniBasket] = useState(false);
    const [menuToggleState, setMenuToggleState] = useState(menuContext);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [searchOverlayOpen, setSearchOverlayOpen] = useState(false);
    const { applyScrollBars, hideScrollBars } = useScrollLock();

    const { cart, shopCheckoutComplete, cartUrl, checkoutUrl } = CartStoreContext.useStoreState((state) => state);

    const { resetSearch } = SearchStoreContext.useStoreActions((actions) => actions);

    // Mini Basket Store
    const { productData } = MiniBasketStore.useStoreState((state) => state);
    // GTM
    const { trackHireLeadCheckout } = useGtmTracking();
    const tracker = trackHireLeadCheckout();

    const showSearch = useMemo(() => {
        return searchSettings && searchSettings.enableSearch;
    }, [searchSettings]);

    const router = useRouter();

    const updateMenuContext = (newState: string) => {
        if (newState === 'privat') {
            router.push('/');
            setMenuToggleState('privat');
        }
        if (newState === 'erhverv') {
            router.push('/erhverv');
            setMenuToggleState('erhverv');
        }
        onMegaMenuChange(false);
    };

    useEffect(() => {
        if (menuContext !== menuToggleState) {
            setMenuToggleState(menuContext);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuContext]);

    const { homeUrl } = useMegaMenu(menuContext);

    const userIsOnCheckoutPage = useMemo(() => {
        return router.asPath.includes(lead_checkout_url) || (checkoutUrl && checkoutUrl.length > 1 && router.asPath.includes(checkoutUrl));
    }, [checkoutUrl, router.asPath]);

    const isDevice = useMediaQuery({ target: 'smallDesktop' });

    const filteredPrivateTopLevelLinks = useMemo(
        () => megaMenuSettings.topLevelLinks.filter((x) => (isDevice ? true : !x.onlyIncludeOnMobile)),
        [isDevice, megaMenuSettings.topLevelLinks]
    );

    const filteredErhvervTopLevelLinks = useMemo(
        () => megaMenuSettings.erhvervTopLevelLinks.filter((x) => (isDevice ? true : !x.onlyIncludeOnMobile)),
        [isDevice, megaMenuSettings.erhvervTopLevelLinks]
    );

    const itemsTotal = useMemo(() => {
        return cart ? cart.lineItems.reduce((prev, curr) => prev + curr.quantity, 0) : 0;
    }, [cart]);

    const { openSearch, isSearchOpen } = useSearchOverlay();

    useEffect(() => {
        if (isSearchOpen) {
            setSearchOverlayOpen(true);
        } else {
            setSearchOverlayOpen(false);
        }
    }, [isSearchOpen]);

    return (
        <StyledHeader>
            {showSearch ? (
                <SearchOverlay isOpen={searchOverlayOpen} searchSettings={searchSettings as SearchSettings} menuContext={menuContext} />
            ) : null}
            <StyledCenteredNonDeviceBlock>
                <StyledToggleWrapper>
                    {!userIsOnCheckoutPage && megaMenuSettings.showPrivaterhvervToggle ? (
                        <ToggleUi
                            options={[
                                { label: 'Privat', value: 'privat' },
                                { label: 'Erhverv', value: 'erhverv' },
                            ]}
                            selectedOption={menuToggleState.toString()}
                            onChange={(selection) => updateMenuContext(selection)}
                        />
                    ) : null}
                    <StyledCartIcons>
                        <UtilityLink
                            onClick={() => {
                                setShowMiniBasket(true);
                                hideScrollBars();
                                tracker.sidebarOpened('Fortsæt din bestilling (mini-basket)');
                            }}
                            style={{
                                display: productData && productData.selectedProduct && !userIsOnCheckoutPage ? 'flex' : 'none',
                            }}
                        >
                            <StyledHireLeadLink>
                                <span>
                                    <SvgIcon iconName="car/car-side-view" color="default" />{' '}
                                </span>
                                <StyledCartOrderlineCountIndicator>1</StyledCartOrderlineCountIndicator>
                            </StyledHireLeadLink>
                            <StyledLink>Fortsæt din bestilling</StyledLink>
                        </UtilityLink>
                        <UtilityLink
                            style={{ display: cart && cart.lineItems.length > 0 && !shopCheckoutComplete && !userIsOnCheckoutPage ? 'flex' : 'none' }}
                        >
                            <Link href={cartUrl} passHref>
                                <StyledCartLink href={cartUrl}>
                                    <SvgIcon iconName="basket" color="default" />{' '}
                                    <StyledCartOrderlineCountIndicator>{itemsTotal}</StyledCartOrderlineCountIndicator>
                                    <span>Kurv</span>
                                </StyledCartLink>
                            </Link>
                        </UtilityLink>
                    </StyledCartIcons>
                </StyledToggleWrapper>
                <StyledNavWrapper>
                    {megaMenuSettings.headerLogo ? (
                        <Link href={homeUrl}>
                            <StyledMegaMenuLogo alt={megaMenuSettings.headerLogo.name} src={MEDIA_URL + megaMenuSettings.headerLogo.src} />
                        </Link>
                    ) : (
                        <Link href={homeUrl} passHref>
                            <StyledTextLogo>{LOGO_TEXT}</StyledTextLogo>
                        </Link>
                    )}
                    {!userIsOnCheckoutPage ? (
                        <StyledNav>
                            {menuContext === 'privat'
                                ? filteredPrivateTopLevelLinks.map((x, idx) => {
                                      return (
                                          <TopLevelItem
                                              key={`${idx}-privat`}
                                              link={x}
                                              megaMenuContent={x.megaMenu ? x.megaMenu[0] : undefined}
                                              onMegaMenuChange={(newState: boolean) => onMegaMenuChange(newState)}
                                          ></TopLevelItem>
                                      );
                                  })
                                : filteredErhvervTopLevelLinks.map((x, idx) => {
                                      return (
                                          <TopLevelItem
                                              key={`${idx}-erhverv`}
                                              link={x}
                                              megaMenuContent={x.megaMenu ? x.megaMenu[0] : undefined}
                                              onMegaMenuChange={(newState: boolean) => onMegaMenuChange(newState)}
                                          ></TopLevelItem>
                                      );
                                  })}
                            <StyledExtraOptions>
                                {showSearch ? (
                                    <UtilityLink
                                        onClick={() => {
                                            openSearch();
                                            resetSearch();
                                        }}
                                    >
                                        <SvgIcon iconName="search" />
                                        <StyledLink>Søg</StyledLink>
                                    </UtilityLink>
                                ) : null}
                                {megaMenuSettings.findDepartmentLink ? (
                                    <UtilityLink
                                        onClick={() => {
                                            router.push(cmsUrlWithoutSiteId(megaMenuSettings.findDepartmentLink.url));
                                        }}
                                    >
                                        <Image src={`${MEDIA_URL}/${dealershipSettings.ctaIcon?.src}` ?? ''} height="25px" width="25px" />
                                        <StyledLink>{dealershipSettings.ctaText}</StyledLink>
                                    </UtilityLink>
                                ) : null}

                                {megaMenuSettings.burgerMenuLinks?.length > 0 ? (
                                    <StyledBurgerItem
                                        onClick={() => {
                                            setBurgerOpen(true);
                                            hideScrollBars();
                                        }}
                                    >
                                        <SvgIcon iconName="menu" />
                                        <span style={{ fontWeight: 500 }}>Mere</span>
                                    </StyledBurgerItem>
                                ) : null}
                            </StyledExtraOptions>
                        </StyledNav>
                    ) : null}
                </StyledNavWrapper>
            </StyledCenteredNonDeviceBlock>
            <StyledCenteredDeviceBlock>
                <StyledMobileNav>
                    {megaMenuSettings.headerLogo ? (
                        <StyledMegaMenuLogo alt={megaMenuSettings.headerLogo.name} src={MEDIA_URL + megaMenuSettings.headerLogo.src} />
                    ) : (
                        <Link href={homeUrl} passHref>
                            <StyledTextLogo>{LOGO_TEXT}</StyledTextLogo>
                        </Link>
                    )}

                    <StyledExtraOptions>
                        {showSearch ? (
                            <UtilityLink
                                onClick={() => {
                                    openSearch();
                                    resetSearch();
                                }}
                            >
                                <SvgIcon iconName="search" />
                            </UtilityLink>
                        ) : null}
                        {megaMenuSettings.findDepartmentLink ? (
                            <UtilityLink
                                onClick={() => {
                                    router.push(cmsUrlWithoutSiteId(megaMenuSettings.findDepartmentLink.url));
                                }}
                            >
                                <Image src={`${MEDIA_URL}/${dealershipSettings.ctaIcon?.src}` ?? ''} height="25px" width="25px" />
                            </UtilityLink>
                        ) : null}
                        {productData && productData.selectedProduct ? (
                            <UtilityLink
                                onClick={() => {
                                    setShowMiniBasket(true);
                                    hideScrollBars();
                                    tracker.sidebarOpened('Fortsæt din bestilling (mini-basket)');
                                }}
                            >
                                <StyledHireLeadLink>
                                    <span>
                                        <SvgIcon iconName="car/car-side-view" color="default" />{' '}
                                    </span>
                                    <StyledCartOrderlineCountIndicator>1</StyledCartOrderlineCountIndicator>
                                </StyledHireLeadLink>
                            </UtilityLink>
                        ) : null}
                        {cart && cart.lineItems.length > 0 && !shopCheckoutComplete ? (
                            <UtilityLink>
                                <Link href={cartUrl} passHref>
                                    <StyledCartLink href={cartUrl}>
                                        <SvgIcon iconName="basket" color="default" />{' '}
                                        <StyledCartOrderlineCountIndicator>{itemsTotal}</StyledCartOrderlineCountIndicator>
                                    </StyledCartLink>
                                </Link>
                            </UtilityLink>
                        ) : null}
                    </StyledExtraOptions>
                    <StyledBurgerItem
                        onClick={() => {
                            setMobileMenuOpen(true);
                            hideScrollBars();
                        }}
                    >
                        <SvgIcon iconName="menu" />
                        <span style={{ fontWeight: 500 }}>Menu</span>
                    </StyledBurgerItem>
                </StyledMobileNav>
            </StyledCenteredDeviceBlock>
            <SidePanel
                isVisible={burgerOpen}
                cancelAction={() => {
                    setBurgerOpen(false);
                    applyScrollBars();
                }}
            >
                <BurgerMenuDesktop
                    closeAction={() => {
                        setBurgerOpen(false);
                        applyScrollBars();
                    }}
                    menuContext={menuContext}
                    links={megaMenuSettings.burgerMenuLinks}
                    erhvervLinks={megaMenuSettings.erhvervBurgerMenuLinks}
                    contactSection={megaMenuSettings as ContactSection}
                    pageId={pageId}
                />
            </SidePanel>
            <SidePanel
                isVisible={mobileMenuOpen}
                cancelAction={() => {
                    setMobileMenuOpen(false);
                    applyScrollBars();
                }}
            >
                <BurgerMenuMobile
                    closeAction={() => {
                        setMobileMenuOpen(false);
                        applyScrollBars();
                    }}
                    menuContext={menuContext}
                    updateMenuContext={(newContext) => updateMenuContext(newContext)}
                    links={menuContext === 'privat' ? filteredPrivateTopLevelLinks : filteredErhvervTopLevelLinks}
                    contactSection={megaMenuSettings as ContactSection}
                    pageId={pageId}
                    showPrivateErhvervToggle={megaMenuSettings.showPrivaterhvervToggle}
                />
            </SidePanel>
            <MiniBasketModal
                showMiniBasket={showMiniBasket}
                hideMiniBasket={() => {
                    setShowMiniBasket(false);
                    applyScrollBars();
                }}
                miniBasketSettings={miniBasketSettings}
            />
        </StyledHeader>
    );
};
