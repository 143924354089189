import styled, { keyframes } from 'styled-components';
import { device } from '../../../lib/media-query';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { getCmsImageUrl } from '../../../utils/helpers';
import { IconButton } from '../../shared/icon-button/icon-button.component';

const getSpacing = (small?: boolean): string => {
    if (small) {
        return '16px';
    } else {
        return '33px';
    }
};

// SPOT
export const StyledSpotHeader = styled(SpotHeader)`
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    white-space: pre-line;

    @media ${device.laptop} {
        color: ${({ theme }) => theme.palette.text.primary};
    }
`;

export const StyledText = styled.div`
    white-space: pre-line;
    margin-top: 1em;
`;

export const StyledTilesList = styled.ul`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: ${getSpacing(true)};
    margin-top: 32px;
    max-width: 100%;

    @media ${device.mobileM} {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media ${device.mobile_tablet} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media ${device.laptop} {
        gap: ${getSpacing()};
        margin-top: 53px;
    }
`;

export const StyledTileSpotCtaWrapper = styled.div`
    margin-top: ${getSpacing(true)};

    @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: ${getSpacing()};

        & button {
            height: 50px;
        }
    }
`;

export const StyledTile = styled.li<{ imageUrl?: string }>`
    background-image: ${(props) => (props.imageUrl ? `url(${getCmsImageUrl(props.imageUrl)})` : 'none')};
    background-position: calc(100% - ${getSpacing(true)}) calc(${getSpacing(true)} / 2);
    background-size: auto 40px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    align-items: flex-start;
    background-color: #fff;
    border-radius: ${({ theme }) => theme.shape.rounded.borderRadiusSm}px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${getSpacing(true)};
    width: 100%;
    cursor: pointer;

    @media ${device.tablet} {
        min-height: 140px;
    }

    @media ${device.laptop} {
        background-position: calc(100% - calc(${getSpacing(true)} / 2)) calc(${getSpacing(true)} / 2);
        background-size: auto 60px;
        padding: ${getSpacing(true)} ${getSpacing()};
        min-height: 164px;
    }
`;

export const StyledTileTopContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${getSpacing()};
    overflow: visible;
    position: relative;
    width: 100%;
`;

export const StyledTileTitle = styled.p`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 26px;
    line-height: 1;
    min-height: 1em;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    z-index: 1;
    max-width: calc(100% - 30px);

    @media ${device.tablet} {
        font-size: 29px;
    }

    @media ${device.laptop} {
        font-size: 33px;
    }
`;

export const StyledTileBottomContent = styled.div`
    margin-top: 0.5em;

    @media ${device.laptop} {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const StyledTileText = styled.p`
    font-size: 14px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: 1.2;

    & + a {
        margin-top: 0.5em;
    }

    @media ${device.laptop} {
        font-size: 18px;
    }
`;

export const StyledTileButton = styled.button`
    background-color: transparent;
    border: none;
    min-height: 2em;
    text-decoration: underline;
    text-underline-offset: 0.35em;
    text-decoration-color: #00000033;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 0;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
`;

// MODAL
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeInContent = keyframes`
  from {
    transform: translateY(15px);
  }
  to {
    transform: translateY(0);
  }
`;

export const StyledModal = styled.div<{ isVisible: boolean }>`
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.6);

    animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.4s ease;
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export const StyledModalContentWrapper = styled.div<{ isVisible: boolean }>`
    background: #fff;
    border-radius: 20px;
    max-width: calc(100% - 30px);
    min-width: 200px;
    padding: 0 ${getSpacing()};
    position: relative;
    width: 600px;
    max-height: 90vh;
    overflow: auto;
    animation: ${(props) => (props.isVisible ? fadeInContent : fadeOut)} 0.4s ease;

    ul,
    ol {
        list-style: unset;
        padding: 10px 25px;
    }
`;

export const StyledModalContent = styled.div`
    margin: ${getSpacing()} 0;
`;

export const StyledTileModalTop = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize + 2}px;
    line-height: 1.2;
    max-width: calc(100% - 2.5em);

    & img {
        min-width: 1em;
        width: 2em;
        flex-shrink: 1;
    }

    & + p {
        margin-top: 1.5em;
        white-space: pre-line;
        /* font-size: ${({ theme }) => theme.typography.small.fontSize}px; */
    }

    @media ${device.laptop} {
        font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize + 6}px;

        & + p {
            font-size: ${({ theme }) => theme.typography.bodyDefault.fontSize}px;
        }
    }
`;

export const StyledIconButton = styled(IconButton)`
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    box-shadow: none;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: transparent;
        border: none;
        box-shadow: none;
        transform: none;
    }
`;
