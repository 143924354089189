import { FC } from 'react';
import { Step } from '../../../hire/hire-lead-checkout/step';
import { ShopStepProps } from '../shop-checkout-page.component';
import { StyledReceiptHeader, StyledReceiptWrapper } from './shop-receipt-step.styled';
import { CheckoutSummaryTotalAndOrderlines } from '../checkout-summary/checkout-summary-total-orderlines.component';
import { StyledInfoWrapper } from '../checkout-summary/checkout-summary.styled';
import { NotesWrapper } from '../../../hire/hire-lead-checkout/checkout-steps/receipt/receipt.styled';
import { NoteWithIcon } from '../../../shared';
import { useRouter } from 'next/router';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';
import { ShopChekcoutDisclaimer } from '../checkout-disclaimer/checkout-disclaimer.component';

export const ShopReceiptStep: FC<ShopStepProps> = ({ currentStep, page }) => {
    const router = useRouter();
    const { cartReceipt } = CartStoreContext.useStoreState((state) => state);

    const installation = false; //TODO INSTALLATION

    return (
        <Step
            edit={() => null}
            goNext={() => router.push('/')}
            isCompleted={false}
            isCurrentStep={currentStep === 4}
            isStepLoading={false}
            isStepValid={true}
            setCanValidateStep={() => null}
            stepNumber={4}
            stepType={'Unknown'}
            title="Kvittering"
            totalSteps={4}
            isCheckoutComplete={false}
            nextStep="Gå til forsiden"
        >
            <StyledReceiptWrapper>
                <StyledReceiptHeader>
                    Tak for din ordre {cartReceipt?.customer?.firstName} - #{cartReceipt?.id}
                </StyledReceiptHeader>
                <p>
                    Om få minutter vil du modtage din bestillingsbekræftelse på {cartReceipt?.customer?.email}. Skulle du imod forventning ikke
                    modtage denne så kig da i din spammappe, da den kan være endt der.
                </p>
                {installation && (
                    <div style={{ marginTop: '20px' }}>
                        <ShopChekcoutDisclaimer disclaimerText="Din ordre kan som udgangspunkt afhentes samlet den dag, du har valgt montering. Hvis du ønsker at afhente nogle af varerne tidligere, er du velkommen til at kontakte os." />
                    </div>
                )}
                <StyledInfoWrapper style={{ margin: '25px 0 40px' }}>
                    <CheckoutSummaryTotalAndOrderlines header="Ordreoversigt" />
                </StyledInfoWrapper>
                <NotesWrapper>
                    <StyledReceiptHeader>Det som kommer til at ske</StyledReceiptHeader>
                    {page.nextSteps.map((x, idx) => {
                        return <NoteWithIcon key={idx} header={x.header} text={x.text} icon={x.icon} index={idx} />;
                    })}
                </NotesWrapper>
                <p style={{ marginBottom: '30px' }}>
                    Måtte du sidde med nogle spørgsmål så tøv endeligt ikke med at kontakte os på <a href="tel:+4572115001">tlf. 7211 5001</a> eller
                    på e-mail <a href="mailto:info@hessel.dk">info@hessel.dk</a>
                </p>
            </StyledReceiptWrapper>
        </Step>
    );
};
