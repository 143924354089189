import { FC } from 'react';
import { SvgIcon } from '../../../svg-icon';
import { StickyClose, StyledLayoutWrapper, StyledMainContent } from './side-panel-layout-desktop.styled';
import { StyledCloseText } from '../mobile/side-panel-layout-mobile.styled';

type SidePanelLayoutDesktopProps = {
    closeSidePanel: () => void;
    showCloseText?: boolean;
};

export const SidePanelLayoutDesktop: FC<SidePanelLayoutDesktopProps> = ({ closeSidePanel, children, showCloseText = false }) => {
    return (
        <StyledLayoutWrapper>
            <StickyClose
                onClick={() => {
                    closeSidePanel();
                }}
            >
                {showCloseText && <StyledCloseText>Luk</StyledCloseText>} <SvgIcon iconName={'close'} />
            </StickyClose>
            <StyledMainContent>{children}</StyledMainContent>
        </StyledLayoutWrapper>
    );
};
