import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { VFC } from 'react';
import { MAP_ZOOM_LEVEL } from '../../../../constants/organization-consts';
import { Spinner } from '../../../shared/spinner';
import { SimpleMap } from '../simple-map/simple-maps.component';

const render = (status: Status, center: { lat: number; lng: number }, zoom: number) => {
    switch (status) {
        case Status.LOADING:
            return <Spinner size="large" variant="dark" />;
        case Status.FAILURE:
            return <p>Error loading google maps</p>;
        case Status.SUCCESS: {
            return <SimpleMap center={center} zoom={zoom} />;
        }
    }
};

type SimpleGoogleMapProps = {
    lat: number;
    lng: number;
    zoom?: number;
};

export const SimpleGoogleMap: VFC<SimpleGoogleMapProps> = ({ lat, lng, zoom = MAP_ZOOM_LEVEL }) => {
    return (
        <Wrapper
            id="hessel-maps"
            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
            render={(status) => render(status, { lat, lng }, zoom)}
        />
    );
};
