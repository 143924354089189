import { postAPI } from '../helpers';
import { ParcelShop } from '../models/shop/parcel-shops';
import { APIResponse } from '../types';

export const getParcelShops = async (zipcode: string, address1: string, city: string): APIResponse<ParcelShop[]> => {
    return postAPI<ParcelShop[]>(`/api/v2/delivery/service-points`, {
        address1,
        city,
        zipcode,
    });
};
