import { FC } from 'react';
import { Column, FormGroup, FormsInput } from '../../../../lib/api/models/umbraco/';
import { ColumnWrapper, BorderBox, MultiInputWrapper, StyledError, FormGroupCaption, CheckboxesWrapper, StyledRedLabel } from './forms-group.styled';
import { Condition } from '../../../../lib/api/models/umbraco';
import { DropdownInput, TextareaInput, TextInput } from '../../../../components/forms/inputs';
import { CheckBox, DatePicker, RadioButton, Separator } from '../../../../components/shared';
import { getFieldTypeAlias, loadComboBoxItems, validateInput } from '../../../../utils/helpers/umbraco-forms.helper';
import { resolveAvailableDates } from './form-group-utils';
import { ComboBox } from '../../../forms/inputs/combos/combo-box';
import { isSiteName, SITE_NAME } from '../../../../utils/helpers/site-id.helper';

type IProps = {
    formGroup: FormGroup;
    conditionEvaluator: (condition: Condition) => boolean;
    onGroupChange: (formGroup: FormGroup) => void;
    componentIdSuffix?: string;
    dropdownColumns?: number;
};

export const FormGroupComponent: FC<IProps> = ({ formGroup, conditionEvaluator, onGroupChange, componentIdSuffix, dropdownColumns }) => {
    const updateField = (input: FormsInput, column: Column) => {
        const oldColumnRef = formGroup.containers.find((col) => col === column);
        if (oldColumnRef) {
            const newInputs = oldColumnRef.fields.map((x) => (x.id === input.id ? input : x));
            const newColumns = formGroup.containers.map((x) => (x === oldColumnRef ? { ...oldColumnRef, fields: newInputs } : x));
            onGroupChange({ ...formGroup, containers: newColumns });
        }
    };

    const validationMessage = (input: FormsInput): string => {
        if (input.mandatory) {
            return input.regex ? input.invalidErrorMessage : input.requiredErrorMessage;
        }

        if (input.regex) {
            return input.invalidErrorMessage;
        }

        return '';
    };

    const getCorrespondingUi = (input: FormsInput, col: Column) => {
        const type = getFieldTypeAlias(input.fieldTypeId);

        switch (type) {
            case 'checkbox':
            case 'dataConsent': {
                const label = type === 'dataConsent' ? input.settings.acceptCopy : input.caption;
                return (
                    <CheckBox
                        id={componentIdSuffix ? `${input.id}-${componentIdSuffix}` : input.id}
                        value={input.value}
                        onChange={(checked) => {
                            updateField({ ...input, value: `${checked}`, valid: input.mandatory ? checked : true, canValidate: true }, col);
                        }}
                        checked={input.value === `${true}`}
                        validationMessage={input.requiredErrorMessage}
                        isValid={input.valid}
                        required={input.mandatory}
                        canValidate={input.canValidate || false}
                        textAlign="top"
                    >
                        <span dangerouslySetInnerHTML={{ __html: label }}></span>
                    </CheckBox>
                );
            }
            case 'password': {
                return (
                    <TextInput
                        id={input.id}
                        label={input.caption}
                        onChange={(e) => {
                            updateField({ ...input, value: e.target.value, valid: validateInput({ ...input, value: e.target.value }) }, col);
                        }}
                        type={type}
                        placeholder={input.settings.placeholder}
                        value={input.value}
                        isValid={input.valid && input.value?.length > 0}
                        canValidateInputField={input.canValidate}
                        onInputBlur={() => {
                            updateField({ ...input, canValidate: input.value.length > 0 }, col);
                        }}
                        validationMessage={validationMessage(input)}
                        required={input.mandatory}
                    />
                );
            }

            case 'shortAnswer': {
                return (
                    <TextInput
                        id={input.id}
                        label={input.caption}
                        onChange={(e) => {
                            updateField({ ...input, value: e.target.value, valid: validateInput({ ...input, value: e.target.value }) }, col);
                        }}
                        type="text"
                        placeholder={input.settings.placeholder}
                        value={input.value}
                        canValidateInputField={input.canValidate}
                        isValid={input.valid && input.value?.length > 0}
                        onInputBlur={() => {
                            updateField({ ...input, canValidate: true }, col);
                        }}
                        validationMessage={validationMessage(input)}
                        required={input.mandatory}
                    />
                );
            }

            case 'longAnswer': {
                return (
                    <TextareaInput
                        id={input.id}
                        hideValidation={false}
                        label={input.caption}
                        placeholder={input.settings.placeholder}
                        value={input.value}
                        canValidateInputField={input.canValidate}
                        isValid={input.valid && input.value?.length > 0}
                        validationMessage={validationMessage(input)}
                        onChange={(e) =>
                            updateField({ ...input, value: e.target.value, valid: validateInput({ ...input, value: e.target.value }) }, col)
                        }
                        onInputBlur={() => updateField({ ...input, canValidate: input.value.length > 0 }, col)}
                        required={input.mandatory}
                    />
                );
            }

            case 'dropdown': {
                if (input.settings.allowMultipleSelections === 'True') {
                    const comboBoxItems = loadComboBoxItems(input);
                    return (
                        <ComboBox
                            required={input.mandatory}
                            id={input.id}
                            key={input.id}
                            isValid={input.valid}
                            value={{ value: input.value?.replace(/,$/, '') ?? '', isSelected: true, type: 'Item', id: 'id' }}
                            items={comboBoxItems}
                            label={input.caption.toUpperCase()}
                            columns={dropdownColumns}
                            placeholder={input.placeholder}
                            hideValidation={input.valid}
                            canValidateInputField={input.canValidate}
                            validationMessage={validationMessage(input)}
                            onComboBoxItemSelected={(checked, item) => {
                                const currentOption = input.preValues?.find((x) => x.value === item?.id);

                                if (!currentOption) {
                                    return;
                                }

                                currentOption.isSelected = checked;

                                const newValue: string = checked
                                    ? `${input.value ?? ''}${currentOption.value},`
                                    : input.value.replace(`${currentOption.value},`, '');

                                updateField(
                                    {
                                        ...input,
                                        value: newValue,
                                        valid: validateInput({ ...input, value: newValue }),
                                        canValidate: true,
                                    },
                                    col
                                );
                            }}
                        />
                    );
                } else {
                    const options = input.preValues.map((option) => {
                        return {
                            displayValue: option.value,
                            value: option.value,
                        };
                    });

                    return (
                        <DropdownInput
                            options={options}
                            value={options.find(({ value }) => input.value === value)}
                            id={input.id}
                            label={input.caption}
                            placeholder={input.settings.placeholder}
                            disabled={options.length === 0}
                            onChange={(option) => {
                                if (option) {
                                    updateField(
                                        { ...input, value: option.value, canValidate: true, valid: validateInput({ ...input, value: option.value }) },
                                        col
                                    );
                                }
                            }}
                            isValid={input.valid}
                            canValidateInputField={input.canValidate}
                            validationMessage={validationMessage(input)}
                            required={input.mandatory}
                        />
                    );
                }
            }

            case 'singleChoice': {
                return (
                    <MultiInputWrapper>
                        <FormGroupCaption>
                            {input.caption}
                            {input.mandatory && <StyledRedLabel>*</StyledRedLabel>}
                        </FormGroupCaption>
                        {input.preValues.map((option, index) => {
                            return (
                                <RadioButton
                                    key={`${type}-${input.id}-${index}`}
                                    id={`${input.id}-${option.value}`}
                                    value={option.value}
                                    checked={input.value === option.value}
                                    action={() => {
                                        updateField({ ...input, value: option.value, valid: validateInput({ ...input, value: option.value }) }, col);
                                    }}
                                    isValid={!input.canValidate || !input.mandatory || input.value !== ''}
                                    required={input.mandatory}
                                >
                                    {option.value}
                                </RadioButton>
                            );
                        })}
                        {input.mandatory && input.value === '' && input.canValidate && <StyledError>{input.requiredErrorMessage}</StyledError>}
                    </MultiInputWrapper>
                );
            }

            case 'multipleChoice': {
                return (
                    <MultiInputWrapper>
                        <FormGroupCaption>
                            {input.caption}
                            {input.mandatory && <StyledRedLabel>*</StyledRedLabel>}
                        </FormGroupCaption>
                        <CheckboxesWrapper>
                            {input.preValues.map((option, index) => {
                                return (
                                    <CheckBox
                                        name={input.id}
                                        key={`${type}-${index}`}
                                        id={`${input.id}-${index}`}
                                        value={option.value}
                                        onChange={(checked) => {
                                            let newValue = input.value ?? '';
                                            if (checked) {
                                                newValue = `${newValue}${option.value},`;
                                            } else {
                                                newValue = newValue.replace(`${option.value},`, '');
                                            }
                                            updateField(
                                                {
                                                    ...input,
                                                    value: newValue,
                                                    valid: validateInput({ ...input, value: newValue }),
                                                    canValidate: true,
                                                },
                                                col
                                            );
                                        }}
                                        checked={input.value !== null && input.value !== undefined && input.value.indexOf(option.value) > -1}
                                        isValid={input.mandatory ? input.value !== '' : true}
                                        canValidate={input.mandatory && input.canValidate}
                                    >
                                        <p>{option.value}</p>
                                    </CheckBox>
                                );
                            })}
                        </CheckboxesWrapper>
                        {input.mandatory && input.canValidate && input.value === '' && <StyledError>{input.requiredErrorMessage}</StyledError>}
                    </MultiInputWrapper>
                );
            }

            case 'titleAndDescription': {
                return (
                    <>
                        <h2 style={{ marginBottom: '1rem', fontSize: '18px' }}>{input.settings.caption}</h2>
                        <p dangerouslySetInnerHTML={{ __html: input.settings.bodyText }}></p>
                    </>
                );
            }

            case 'fileUpload': {
                return (
                    <TextInput
                        id={input.id}
                        label={input.caption}
                        onChange={(e) => {
                            updateField(
                                {
                                    ...input,
                                    file: e.target.files !== null ? e.target.files[0] : undefined,
                                    valid: e.target.files !== null,
                                },
                                col
                            );
                        }}
                        type="file"
                        placeholder={input.settings.placeholder}
                        value={input.value}
                        isValid={validateInput(input)}
                        canValidateInputField={input.canValidate}
                        validationMessage={input.invalidErrorMessage}
                        required={input.mandatory}
                    />
                );
            }

            case 'date':
            case 'advancedDatepicker': {
                const availableDates = resolveAvailableDates(input);
                const currentDate =
                    input.value?.length > 0 ? new Date(input.value) : availableDates.find((x) => x.getDay() !== 0 && x.getDay() !== 6);
                return (
                    <DatePicker
                        selectedDate={currentDate}
                        onSelect={(e) => {
                            updateField({ ...input, value: e.toLocaleDateString('en-US'), valid: true }, col);
                        }}
                        modalHeaderText={'Vælg dato'}
                        modalConfirmText={'Vælg'}
                        modalCancelText={'Luk'}
                        loadingDays={false}
                        availableDays={availableDates}
                        mobileRenderMethod="Inline"
                        includeWeekends={type === 'advancedDatepicker'}
                    />
                );
            }

            default:
                return <h3>Invalid input</h3>;
        }
    };

    return (
        <ColumnWrapper columnCount={formGroup.containers.length}>
            {formGroup.containers.map((col, index) => {
                return (
                    <BorderBox key={`${col}-${index}`}>
                        {formGroup.caption?.length > 0 && <FormGroupCaption>{formGroup.caption}</FormGroupCaption>}
                        {col.fields.map((f, formGroupIndex) => {
                            if (!conditionEvaluator(f.condition)) {
                                return null;
                            }
                            //Specific rule for hidden input fields, since we don't want to outer div to take up DOM-space
                            if (getFieldTypeAlias(f.fieldTypeId) === 'hidden') {
                                return <input key={formGroupIndex} type="hidden" value={f.settings.defaultValue} />;
                            }
                            return (
                                <div id={`input-${f.id}`} key={formGroupIndex} style={{ marginBottom: 'unset' }}>
                                    {getFieldTypeAlias(f.fieldTypeId) === 'dataConsent' &&
                                    col.fields.filter((x) => getFieldTypeAlias(x.fieldTypeId) === 'dataConsent').indexOf(f) === 0 &&
                                    !isSiteName(SITE_NAME.CARSAVER) ? (
                                        <Separator marginBottom="24px" />
                                    ) : null}
                                    {getCorrespondingUi(f, col)}
                                </div>
                            );
                        })}
                    </BorderBox>
                );
            })}
        </ColumnWrapper>
    );
};
