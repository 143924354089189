import { FC } from 'react';
import { SearchStoreContext } from '../../../lib/state/search/search.store';
import { ShopProductCard } from '../../shop/shop-product-card/shop-product-card.component';
import Link from 'next/link';
import { StyledLoadMoreButonContainer, StyledWebshopProductWrapper, StyledWebshopSection } from './search-results.styled';
import { Button } from '../../shared';
import { useSegmentContext } from '../../../hooks/use-segment-context';

export const WebshopProductsSearchResults: FC = () => {
    const { searchWebshopProductsResults, searchTerm, currentPageWebshopProductsPage, webshopProductSearchTotalCount } =
        SearchStoreContext.useStoreState((state) => state);

    const { getNextPageWebshopProductResults } = SearchStoreContext.useStoreActions((actions) => actions);

    const segment = useSegmentContext();

    return (
        <StyledWebshopSection>
            <StyledWebshopProductWrapper>
                {searchWebshopProductsResults.map((product) => {
                    return (
                        <Link key={product.productId} href={product.data?.Url.value}>
                            <a href={product.data?.Url.value} style={{ textDecoration: 'none' }}>
                                <ShopProductCard key={product.productId} product={product} />
                            </a>
                        </Link>
                    );
                })}
            </StyledWebshopProductWrapper>
            {webshopProductSearchTotalCount > searchWebshopProductsResults.length && (
                <StyledLoadMoreButonContainer>
                    <Button
                        variant="dark"
                        onClick={() => getNextPageWebshopProductResults({ searchTerm, page: currentPageWebshopProductsPage + 1, segment })}
                    >
                        Indlæs flere
                    </Button>
                </StyledLoadMoreButonContainer>
            )}
        </StyledWebshopSection>
    );
};
