import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { Button } from '../../../shared';

export const StyledDealershipMap = styled.div`
    width: 100%;
    height: 600px;

    div[role='dialog'].gm-style-iw {
        background-color: #f2f2f2;
        border: 4px solid white;
        padding-right: 0;

        @media screen and (max-width: 478px) {
            max-width: 300px !important;
        }

        .gm-style-iw-chr {
            button {
                width: 20px !important;
                height: 30px !important;

                span {
                    width: 14px !important;
                    height: 14px !important;
                    margin: 0 !important;
                }
            }
        }

        .gm-style-iw-d {
            overflow: auto !important;
            max-width: 400px;
        }
    }
`;

export const StyledInputAndErrorWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin: 25px 0 40px;
    justify-content: flex-start;
    flex-direction: column;
    @media ${device.laptop} {
        flex-direction: row;
        align-items: center;
    }
`;

export const StyledGeoInputFilterWrapper = styled.form`
    display: grid;
    grid-template-columns: 1fr 90px;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    @media ${device.laptop} {
        gap: 15px;
    }
`;

export const StyledButton = styled(Button)`
    height: 50px;
`;

export const StyledMapAndListContainer = styled.div`
    display: grid;

    @media ${device.tablet} {
        grid-template-columns: 460fr 880fr;
    }
`;

export const StyledListMapToggle = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
    @media ${device.laptop} {
        display: none;
    }

    p {
        border-bottom: 1px solid #f7941d;
    }
`;

export const StyledErrorMessage = styled.p`
    color: rgb(136 12 12);
`;
