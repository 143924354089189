import { FC } from 'react';
import { SvgIcon } from '../../../svg-icon';
import { Close, StyledLayoutWrapper, StyledHeaderSection, StyledMainContent, StyledCloseText } from './side-panel-layout-mobile.styled';

type SidePanelLayoutMobileProps = {
    closeSidePanel: () => void;
    showCloseText?: boolean;
};

export const SidePanelLayoutMobile: FC<SidePanelLayoutMobileProps> = ({ closeSidePanel, children, showCloseText = false }) => {
    return (
        <StyledLayoutWrapper>
            <StyledHeaderSection>
                <Close
                    onClick={() => {
                        closeSidePanel();
                    }}
                >
                    {showCloseText && <StyledCloseText>Luk</StyledCloseText>} <SvgIcon iconName={'close'} />
                </Close>
            </StyledHeaderSection>
            <StyledMainContent>{children}</StyledMainContent>
        </StyledLayoutWrapper>
    );
};
