import Link from 'next/link';
import { FC } from 'react';
import { PrimaryLink as PrimaryLinkType } from '../../../lib/api/models/umbraco/mega-menu.types';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';
import { SvgIcon } from '../../shared/svg-icon';
import {
    StyledIcon,
    StyledLinkTextWrapper,
    StyledPrimaryLinkBlock,
    StyledPrimaryLinkDescription,
    StyledPrimaryLinkText,
} from './primary-link.styled';

type IProps = {
    border?: boolean;
    link: PrimaryLinkType;
    onLinkClick: () => void;
};

export const PrimaryLink: FC<IProps> = ({ border = true, link, onLinkClick }) => {
    return (
        <Link href={cmsUrlWithoutSiteId(link.linkDestination.url)} passHref>
            <StyledPrimaryLinkBlock border={border} onClick={() => onLinkClick()} target={link.linkDestination.target}>
                <StyledIcon>
                    <SvgIcon iconName={'arrow/right'} color="light" />
                </StyledIcon>
                <StyledLinkTextWrapper>
                    <StyledPrimaryLinkText>{link.linkText}</StyledPrimaryLinkText>
                    <StyledPrimaryLinkDescription>{link.linkDescription}</StyledPrimaryLinkDescription>
                </StyledLinkTextWrapper>
            </StyledPrimaryLinkBlock>
        </Link>
    );
};
