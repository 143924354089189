import { action, persist, thunk } from 'easy-peasy';
import { SearchActions, SearchState, SearchStore, SearchThunks } from './search.types';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { doRelewiseSearch, getNextPageOfVehicles, getNextPageOfWebshopProducts, getPopularSearchTerms } from '../../api/search/search.api';

const searchDefaultState = (runtimeModel: Partial<SearchState> | undefined): SearchState => ({
    searchTerm: runtimeModel?.searchTerm ?? '',
    searchVehicleResults: runtimeModel?.searchVehicleResults ?? [],
    searchReturnedNoResults: runtimeModel?.searchReturnedNoResults ?? false,
    searchPredictionResults: runtimeModel?.searchPredictionResults ?? [],
    popularSearchTerms: runtimeModel?.popularSearchTerms ?? [],
    searchContentResults: runtimeModel?.searchContentResults ?? [],
    vehicleSearchTotalCount: runtimeModel?.vehicleSearchTotalCount ?? 0,
    currentPage: runtimeModel?.currentPage ?? 1,
    currentPageWebshopProductsPage: runtimeModel?.currentPageWebshopProductsPage ?? 1,
    searchWebshopProductsResults: runtimeModel?.searchWebshopProductsResults ?? [],
    webshopProductSearchTotalCount: runtimeModel?.webshopProductSearchTotalCount ?? 0,
});

const searchActions = (): SearchActions => ({
    setSearchTerm: action((state, payload) => {
        state.searchTerm = payload;
    }),
    setSearchVehicleResults: action((state, payload) => {
        state.searchVehicleResults = payload;
    }),
    setSearchReturnedNoResults: action((state, payload) => {
        state.searchReturnedNoResults = payload;
    }),
    setSearchPredictionResults: action((state, payload) => {
        state.searchPredictionResults = payload;
    }),
    resetSearch: action((state) => {
        state.searchTerm = '';
        state.searchVehicleResults = [];
        state.searchWebshopProductsResults = [];
        state.searchReturnedNoResults = false;
        state.searchPredictionResults = [];
        state.popularSearchTerms = [];
        state.searchContentResults = [];
        state.currentPage = 1;
        state.currentPageWebshopProductsPage = 1;
    }),
    setPopularSearchTerms: action((state, payload) => {
        state.popularSearchTerms = payload;
    }),
    setContentResults: action((state, payload) => {
        state.searchContentResults = payload;
    }),
    setVehicleSearchTotalCount: action((state, payload) => {
        state.vehicleSearchTotalCount = payload;
    }),
    addToSearchVehicleResults: action((state, payload) => {
        state.searchVehicleResults = [...state.searchVehicleResults, ...payload];
    }),
    setCurrentPage: action((state, payload) => {
        state.currentPage = payload;
    }),
    setSearchWebshopProductsResults: action((state, payload) => {
        state.searchWebshopProductsResults = payload;
    }),
    setWebshopProductSearchTotalCount: action((state, payload) => {
        state.webshopProductSearchTotalCount = payload;
    }),
    setCurrentPageWebshopProducts: action((state, payload) => {
        state.currentPageWebshopProductsPage = payload;
    }),
    addToSearchWebshopProductsResults: action((state, payload) => {
        state.searchWebshopProductsResults = [...state.searchWebshopProductsResults, ...payload];
    }),
});

const searchThunks = (): SearchThunks => ({
    doSearch: thunk(async (actions, payload) => {
        const result = await doRelewiseSearch(payload.searchTerm, payload.segment);

        if (result) {
            actions.setSearchReturnedNoResults(
                result.vehicleProductSearchResults.results?.length === 0 &&
                    result.contentSearchResults.results?.length === 0 &&
                    result.webshopProductSearchResults.results?.length === 0
            );
            if (result.vehicleProductSearchResults) {
                actions.setSearchVehicleResults(result.vehicleProductSearchResults.results ?? []);
                actions.setVehicleSearchTotalCount(result.vehicleProductSearchResults.hits ?? 0);
            }
            if (result.searchTermsResults) {
                actions.setSearchPredictionResults(result.searchTermsResults.predictions ?? []);
            }
            if (result.contentSearchResults) {
                actions.setContentResults(result.contentSearchResults.results ?? []);
            }
            if (result.webshopProductSearchResults) {
                actions.setSearchWebshopProductsResults(result.webshopProductSearchResults.results ?? []);
                actions.setWebshopProductSearchTotalCount(result.webshopProductSearchResults.hits ?? 0);
            }
        }
    }),
    getPopularSearchTerms: thunk(async (actions, payload) => {
        const result = await getPopularSearchTerms(payload.segment);
        if (result) {
            actions.setPopularSearchTerms(result.recommendations ?? []);
        }
    }),
    getNextPageVehicleResults: thunk(async (actions, payload) => {
        const result = await getNextPageOfVehicles(payload.searchTerm, payload.page, payload.segment);
        if (result) {
            actions.addToSearchVehicleResults(result.results ?? []);
            actions.setCurrentPage(payload.page);
        }
    }),
    getNextPageWebshopProductResults: thunk(async (actions, payload) => {
        const result = await getNextPageOfWebshopProducts(payload.searchTerm, payload.page, payload.segment);
        console.log('jakob test nextPage', result);
        if (result) {
            actions.addToSearchWebshopProductsResults(result.results ?? []);
            actions.setCurrentPageWebshopProducts(payload.page);
        }
    }),
});

export const SearchStoreContext = createContextStoreWithRuntimeModel<SearchStore, Partial<SearchState>>(
    (runtimeModel) =>
        persist(
            {
                ...searchDefaultState(runtimeModel),
                ...searchActions(),
                ...searchThunks(),
            },
            { storage: 'localStorage' }
        ),
    { name: 'SearchStore' }
);
