import styled, { css } from 'styled-components';
import { isSiteName, SITE_NAME } from '../../../../../utils/helpers/site-id.helper';

export const StyledLayoutWrapper = styled.section`
    display: flex;
    flex-direction: column;

    padding: 47px;
    padding-right: 25px;
    padding-top: 30px;
`;

export const StickyClose = styled.div`
    position: sticky;
    top: 30px;
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 53px;

    ${isSiteName(SITE_NAME.CARSAVER) &&
    css`
        position: static;
    `}
`;

export const StyledMainContent = styled.div`
    padding-right: 55px;
`;
