import styled, { css } from 'styled-components';
import { CtaHandler } from '../../cta-handler';

export const StyledFooterDesktop = styled.footer<{ marginTop?: string }>`
    background-color: ${({ theme }) => theme.palette.footer.background};
    color: ${({ theme }) => theme.palette.footer.color};
    font-size: ${({ theme }) => theme.typography.footer.fontSize}px;
    font-weight: ${({ theme }) => theme.typography.footer.fontWeight};
    letter-spacing: 0.5px;
    line-height: 16px;
    padding: 3.125rem 0;
    position: relative;
    width: 100%;
    ${(props) =>
        props.marginTop &&
        props.marginTop.length > 0 &&
        css`
            margin-top: ${props.marginTop};
        `}
`;

export const FooterTopShape = styled.div`
    height: 40px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -40px;
    background: rgb(247, 148, 29);
    background: linear-gradient(1deg, rgba(247, 148, 29, 1) 0%, rgba(247, 148, 29, 1) 50%, rgba(247, 247, 248, 1) 50%, rgba(247, 247, 248, 1) 100%);
`;

export const StyledFooterFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomerInfoDesktop = styled.section<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    justify-self: center;
    grid-gap: 3rem;
`;

export const Content = styled.div<{ alternativeLayout: boolean }>`
    display: flex;
    flex-direction: column;

    ${(props) =>
        props.alternativeLayout &&
        css`
            justify-content: space-between;
            flex-direction: row;
        `}
`;

export const OpeningHoursWrapper = styled.section<{ alternativeLayout: boolean }>`
    margin: 1.5rem 0;

    ${(props) =>
        props.alternativeLayout &&
        css`
            margin: 0;
        `}
`;

export const OpeningHoursContent = styled.div<{ isCarSaver: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${({ isCarSaver }) =>
        isCarSaver
            ? css`
                  gap: 15px;
              `
            : css`
                  width: 175px;
              `}
`;

export const StyledPaymentWrapper = styled.section<{ alternativeLayout: boolean }>`
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;

    ${(props) =>
        props.alternativeLayout &&
        css`
            align-self: flex-end;
            margin: 0;
        `}
`;

export const CreditsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 200px;
`;

export const CreditCard = styled.div`
    width: 40px;
    height: 40px;

    & img {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const StyledCtaHandler = styled(CtaHandler)`
    display: flex;
    align-items: center;
    color: black;

    & a {
        display: inline-block;
    }
`;

export const CtaHandlerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const SmallTextDesktop = styled.p<{ showAsItalic?: boolean }>`
    font-style: ${(props) => (props.showAsItalic ? 'italic' : '')};
    white-space: nowrap;
`;
