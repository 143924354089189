import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSpotWrapper = styled.div`
    padding: 32px 0;
    @media ${device.tablet} {
        padding: 70px 0;
    }
`;

export const StyledMapContainer = styled.div``;

export const StyledTextsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 600px;
`;
