import { ProductResult } from '@relewise/client';
import { ShopProductDetails, StockStatus } from '../../../lib/api/models/shop';
import { getImageCropUrl } from '../../../utils/helpers';

type ProductAttributes = {
    name: string;
    imageUrl: string;
    promotionIds: string[];
    shortDescription: string;
    stockStatus: StockStatus;
    originalPrice: number;
    price: number;
};

export const getProductAttributes = (product: ShopProductDetails | ProductResult): ProductAttributes => {
    if ('id' in product) {
        const typeCastProduct = product as ShopProductDetails;
        return {
            name: typeCastProduct.name,
            imageUrl: getImageCropUrl(typeCastProduct.resources[0].data, 'productlist'),
            promotionIds: typeCastProduct.promotionIds === null ? [] : typeCastProduct.promotionIds,
            shortDescription: typeCastProduct.shortDescription,
            stockStatus: typeCastProduct.stockStatus,
            originalPrice: typeCastProduct.originalPrice,
            price: typeCastProduct.price,
        };
    } else {
        const typeCastProduct = product as ProductResult;
        return {
            name: typeCastProduct.displayName ?? '',
            imageUrl: getImageCropUrl(typeCastProduct.data?.ImageUrl.value ?? '', 'productlist'),
            promotionIds: typeCastProduct.data?.PromotionIds?.value.$values ?? [],
            shortDescription: typeCastProduct.data?.ShortDescription.value ?? '',
            stockStatus: typeCastProduct.data?.StockStatus.value ?? 'OutOfStock',
            originalPrice: typeCastProduct.data?.OriginalPrice.value.amount ? Number(typeCastProduct.data?.OriginalPrice.value.amount) : 0,
            price: typeCastProduct.salesPrice ?? 0,
        };
    }
};
