import { FC, useEffect, useState } from 'react';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { StyledMapContainer, StyledSpotWrapper, StyledTextsWrapper } from './carsaver-workshops-map.styled';
import { CarSaverWorkshopsMap as CarSaverWorkshopsMapType } from '../../../lib/api/models/umbraco/content-spot';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Spinner } from '../../shared/spinner';
import { CarSaverMap } from './carsaver-map/carsaver-map.component';
import { CarSaverWorkshop, getCarSaverWorkshops } from '../../../lib/api/organization/carsaver-dealership-api';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
type IProps = {
    spot: CarSaverWorkshopsMapType;
};

const render = (status: Status, center: { lat: number; lng: number }, zoom: number, workshops: CarSaverWorkshop[]) => {
    switch (status) {
        case Status.LOADING:
            return <Spinner size="large" variant="dark" />;
        case Status.FAILURE:
            return <p>Error loading google maps</p>;
        case Status.SUCCESS: {
            return <CarSaverMap center={center} zoom={zoom} workshops={workshops} />;
        }
    }
};

export const CarSaverWorkshopsMap: FC<IProps> = ({ spot }) => {
    const [workshops, setWorkshops] = useState<CarSaverWorkshop[]>([]);
    useEffect(() => {
        const getWorkshop = async () => {
            const [result, error] = await getCarSaverWorkshops();
            if (result && !error) {
                setWorkshops(result);
            }
            // setWorkshops(CarSaverWorkshops);
        };
        getWorkshop();
    }, []);

    return (
        <StyledSpotWrapper
            style={
                spot.backgroundColor && spot.backgroundColor.length > 0
                    ? { backgroundColor: `#${spot.backgroundColor}` }
                    : { backgroundColor: '#f2f2f2' }
            }
        >
            <CenteredBlock>
                <StyledMapContainer>
                    <StyledTextsWrapper>
                        <SpotHeader headerSize={spot.headerSize} headerType={spot.headerType} style={{ color: `#${spot.textColor}` }}>
                            {spot.header}
                        </SpotHeader>
                        {spot.subtext.length > 0 && (
                            <div
                                style={{ color: `#${spot.textColor}` }}
                                dangerouslySetInnerHTML={{ __html: spot.subtext.replace('{{amount}}', workshops.length.toString()) }}
                            ></div>
                        )}
                    </StyledTextsWrapper>

                    {workshops.length > 0 && (
                        <Wrapper
                            id="carsaver-maps"
                            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
                            render={(status) => render(status, { lat: 56, lng: 11 }, 7, workshops)}
                        />
                    )}
                </StyledMapContainer>
            </CenteredBlock>
        </StyledSpotWrapper>
    );
};
