import React, { VFC, useMemo } from 'react';
import {
    CustomerInfoDesktop,
    StyledFooterDesktop,
    Content,
    OpeningHoursWrapper,
    OpeningHoursContent,
    CreditsWrapper,
    CreditCard,
    CtaHandlerWrapper,
    SmallTextDesktop,
    StyledPaymentWrapper,
    StyledFooterFlexContainer,
    StyledCtaHandler,
} from './footer-desktop.styled';
import Link from 'next/link';
import {
    SmallText,
    TextGroup,
    StyledBrand,
    SmallLink,
    ContentTitle,
    OpeningHoursTitle,
    StyledFooterLogo,
    StyledFooterShape,
    StyledTextGroup,
    StyledSocialMediaWrapper,
    StyledSocialItem,
    StyledSocialList,
    SmallLinkWrapper,
    CarSaverSmallLink,
    CarSaverFooterTextGroup,
    StyledBackgroundColorFill,
} from '../footer.styled';
import { umbraco } from '../../../../lib/api';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { CenteredBlock } from '../../../structural-blocks/centered-block.component';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { MenuContext } from '../../../mega-menu/mega-menu.component';
import { FooterShape } from '../footer-shape.component';
import { SITE_NAME, isSiteName } from '../../../../utils/helpers/site-id.helper';

type IProps = umbraco.FooterContent & {
    menuContext?: MenuContext;
    backgroundColor?: string;
};

export const FooterDesktop: VFC<IProps> = ({
    footerLogo,
    companyName,
    companyAddress,
    companyEmail,
    cvr,
    telephone,
    columns,
    erhvervColumns,
    openingHours = [],
    paymentIcons,
    menuContext,
    socialMediaHeader,
    socialMediaLinks,
    backgroundColor,
}) => {
    const columnsToRender = useMemo(() => {
        if (menuContext === 'erhverv') return erhvervColumns;
        return columns;
    }, [columns, erhvervColumns, menuContext]);

    return (
        <StyledFooterDesktop marginTop={backgroundColor === 'f7941d' ? '-90px' : undefined}>
            {isSiteName(SITE_NAME.CARSAVER) && backgroundColor !== 'f7941d' && (
                <>
                    <StyledBackgroundColorFill bgColor={`#${backgroundColor}` ?? 'transparent'} />
                    <StyledFooterShape bgColor={`#${backgroundColor}` ?? 'transparent'}>
                        <FooterShape></FooterShape>
                    </StyledFooterShape>
                </>
            )}
            <CenteredBlock>
                <StyledFooterFlexContainer>
                    {footerLogo ? (
                        <StyledFooterLogo
                            maxWidth={isSiteName(SITE_NAME.CARSAVER) ? '158px' : '250px'}
                            src={MEDIA_URL + footerLogo.src}
                            alt={footerLogo.name}
                            style={isSiteName(SITE_NAME.CARSAVER) ? { marginBottom: '48px' } : {}}
                        />
                    ) : (
                        !isSiteName(SITE_NAME.CARSAVER) && <StyledBrand>Ejner Hessel</StyledBrand>
                    )}
                    <CustomerInfoDesktop columns={isSiteName(SITE_NAME.CARSAVER) ? 1 : columnsToRender.length + 1}>
                        <Content alternativeLayout={isSiteName(SITE_NAME.CARSAVER)}>
                            {isSiteName(SITE_NAME.CARSAVER) ? (
                                <div>
                                    <StyledTextGroup style={{ fontSize: '18px' }}>
                                        <strong>{companyAddress}</strong>
                                    </StyledTextGroup>

                                    <CarSaverFooterTextGroup>
                                        <SmallText>
                                            <strong>CVR nr.:</strong> <span>{cvr}</span>
                                        </SmallText>
                                    </CarSaverFooterTextGroup>

                                    <CarSaverFooterTextGroup>
                                        <strong>Tlf. nr.:</strong>
                                        <Link href={`tel:${telephone}`} passHref={true}>
                                            <CarSaverSmallLink>{telephone}</CarSaverSmallLink>
                                        </Link>
                                    </CarSaverFooterTextGroup>

                                    <CarSaverFooterTextGroup>
                                        <strong>E-mail:</strong>
                                        <Link href={`mailto:${companyEmail}`} passHref={true}>
                                            <CarSaverSmallLink>{companyEmail}</CarSaverSmallLink>
                                        </Link>
                                    </CarSaverFooterTextGroup>

                                    {columnsToRender.map((x, index) => {
                                        return (
                                            <TextGroup
                                                key={`footer-desktop-col-${x.title}-${index}`}
                                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}
                                            >
                                                <strong>{x.title}:</strong>
                                                <SmallLinkWrapper>
                                                    <StyledCtaHandler ctas={x.links} fontSize="medium" pageSection="Footer" />
                                                </SmallLinkWrapper>
                                            </TextGroup>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>
                                    <TextGroup>
                                        <p>{companyName}</p>
                                    </TextGroup>
                                    <TextGroup>
                                        <p>{companyAddress}</p>
                                    </TextGroup>

                                    <TextGroup>
                                        <SmallText>CVR nr.: {cvr}</SmallText>
                                    </TextGroup>

                                    <TextGroup>
                                        <p>Tlf. nr.:</p>
                                        <Link href={`tel:${telephone}`} passHref={true}>
                                            <SmallLink>{telephone}</SmallLink>
                                        </Link>
                                    </TextGroup>

                                    <TextGroup>
                                        <p>E-mail:</p>
                                        <Link href={`mailto:${companyEmail}`} passHref={true}>
                                            <SmallLink>{companyEmail}</SmallLink>
                                        </Link>
                                    </TextGroup>
                                </div>
                            )}

                            <OpeningHoursWrapper alternativeLayout={isSiteName(SITE_NAME.CARSAVER)}>
                                {openingHours && openingHours.length > 0 ? (
                                    <OpeningHoursTitle>{isSiteName(SITE_NAME.CARSAVER) ? 'Kundeservice' : 'Åbningstider'}</OpeningHoursTitle>
                                ) : null}
                                {openingHours?.map((x, index) => (
                                    <OpeningHoursContent key={`${companyName}-${index}`} isCarSaver={isSiteName(SITE_NAME.CARSAVER)}>
                                        <SmallText>{x.days}</SmallText>
                                        {x.alias === 'openingHour' && <SmallTextDesktop showAsItalic={!x.isOpen}>{x.hours}</SmallTextDesktop>}
                                        {x.alias === 'openingHourLink' && (
                                            <Link href={x.ctaUrl.url}>
                                                <SmallLink>{x.ctaUrl.name}</SmallLink>
                                            </Link>
                                        )}
                                    </OpeningHoursContent>
                                ))}
                            </OpeningHoursWrapper>

                            <div>
                                {isSiteName(SITE_NAME.CARSAVER) && socialMediaLinks && (
                                    <StyledSocialMediaWrapper>
                                        {socialMediaHeader && (
                                            <p style={{ marginBottom: '8px' }}>
                                                <strong>{socialMediaHeader}</strong>
                                            </p>
                                        )}
                                        <StyledSocialList>
                                            {socialMediaLinks.map((x, index) => {
                                                return (
                                                    <StyledSocialItem key={index}>
                                                        <Link href={x.mediaLink.url}>
                                                            <a href={x.mediaLink.url}>
                                                                {x.mediaIcon && x.mediaIcon.src ? (
                                                                    <img src={MEDIA_URL + x.mediaIcon.src} alt={x.mediaName} />
                                                                ) : (
                                                                    <SmallLink>{x.mediaName}</SmallLink>
                                                                )}
                                                            </a>
                                                        </Link>
                                                    </StyledSocialItem>
                                                );
                                            })}
                                        </StyledSocialList>
                                    </StyledSocialMediaWrapper>
                                )}

                                {paymentIcons && paymentIcons.length > 0 ? (
                                    <StyledPaymentWrapper alternativeLayout={isSiteName(SITE_NAME.CARSAVER)}>
                                        {isSiteName(SITE_NAME.CARSAVER) ? (
                                            <p>
                                                <strong>Sikker betaling</strong>
                                            </p>
                                        ) : (
                                            <p>Sikker betaling</p>
                                        )}
                                        <CreditsWrapper>
                                            {paymentIcons.map((x, index) => {
                                                return (
                                                    <CreditCard key={`footer-desktop-payment-${index}`}>
                                                        <img src={`${MEDIA_URL}/${x.src}`} alt={x.name} loading="lazy" />
                                                    </CreditCard>
                                                );
                                            })}
                                        </CreditsWrapper>
                                    </StyledPaymentWrapper>
                                ) : null}
                            </div>
                        </Content>

                        {!isSiteName(SITE_NAME.CARSAVER) &&
                            columnsToRender.map((x, index) => {
                                return (
                                    <Content alternativeLayout={false} key={`footer-desktop-col-${x.title}-${index}`}>
                                        <ContentTitle>{x.title}</ContentTitle>

                                        <CtaHandlerWrapper>
                                            <CtaHandler ctas={x.links} pageSection="Footer" />
                                        </CtaHandlerWrapper>
                                    </Content>
                                );
                            })}
                    </CustomerInfoDesktop>
                </StyledFooterFlexContainer>
            </CenteredBlock>
        </StyledFooterDesktop>
    );
};
