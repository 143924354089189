import { FC } from 'react';
import { StyledListWrapper, StyledPinIcon, StyledWorkshopWrapper } from './carsaver-workshops-list.styled';
import { CarSaverWorkshop } from '../../../../lib/api/organization/carsaver-dealership-api';
import { CarSaverWorkshopInfoWindow } from '../carsaver-map-markers/carsaver-workshop-info-window/carsaver-workshop-info-window.component';

type IProps = {
    workshops: CarSaverWorkshop[];
    hideList: boolean;
};

export const CarSaverWorkshopsList: FC<IProps> = ({ workshops, hideList }) => {
    return (
        <StyledListWrapper hide={hideList}>
            {workshops.map((workshop) => {
                return (
                    <StyledWorkshopWrapper key={workshop.id}>
                        <StyledPinIcon src="/png/carsaver-workshop-pin.png" alt="CarSaver lokationspin" />
                        <div>
                            <CarSaverWorkshopInfoWindow workshop={workshop} />
                        </div>
                    </StyledWorkshopWrapper>
                );
            })}
        </StyledListWrapper>
    );
};
