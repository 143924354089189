import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledInfoWindow = styled.div`
    padding-right: 12px;
    font-family: 'Paralucent';
    max-width: 400px;
    max-height: 400px;
    padding: 0 10px 20px 0;
    font-size: 14px;
    font-weight: 400;

    @media ${device.laptop} {
        padding: 0 20px 20px;
    }
`;
