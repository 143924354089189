import { FC } from 'react';
import { StyledContentWrapper, StyledFormHeader, StyledFormsContainer, StyledTeaserText } from './carsaver-form.styled';
import { CarSaverForm as CarSaverFormType } from '../../../lib/api/models/umbraco/content-spot';
import { SpotHeader } from '../../shared/typography/spot-header/spot-header.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { UmbracoForms } from '../umbraco-forms';

type IProps = {
    spot: CarSaverFormType;
    pageId: string;
    isFirstSpotInList: boolean;
};

export const CarSaverForm: FC<IProps> = ({ spot, pageId, isFirstSpotInList }) => {
    return (
        <StyledContentWrapper style={{ backgroundColor: `#${spot.backgroundColor}` }} firstInList={isFirstSpotInList}>
            <CenteredBlock>
                <StyledTeaserText style={{ color: `#${spot.textColor}` }}>{spot.teaserHeader}</StyledTeaserText>
                <SpotHeader headerSize={spot.headerSize} headerType={spot.headerType} style={{ color: `#${spot.textColor}` }}>
                    {spot.header}
                </SpotHeader>
                <StyledFormHeader style={{ color: `#${spot.textColor}` }}>{spot.formHeader}</StyledFormHeader>
                <StyledFormsContainer bgColor={spot.backgroundColor} textColor={spot.textColor.length > 0 ? spot.textColor : '0b0b0b'}>
                    <UmbracoForms form={spot.form} pageId={pageId} buttonVariant={spot.formCTAVariant} />
                </StyledFormsContainer>
            </CenteredBlock>
        </StyledContentWrapper>
    );
};
