import { VFC, useMemo } from 'react';
import { sharedTypes } from '../../../lib/api/models/umbraco';
import { ComparisonModule } from '../../../lib/api/models/umbraco/content-spot';
import { ContentSpotRichText } from '../../shared/content-spot-rich-text/content-spot-rich-text.component';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { ComparisonTable } from '../comparison-table';
import { BorderBox, Header, CenteredHeader, Footnotes, FootnoteText, DisplayContainer, StyledCenteredHeaderSpot } from './comparison-module.styled';
import { isNullOrEmpty } from '../../../utils/helpers';

export type ComparisonModuleUiProps = {
    tableData: ComparisonModule;
    headerPosition?: sharedTypes.ElementPosition;
};

export const ComparisonModuleUi: VFC<ComparisonModuleUiProps> = ({ tableData, headerPosition = 'Left' }) => {
    tableData.tableWidth = useMemo(() => {
        if (isNullOrEmpty(tableData.tableWidth)) return '100%';
        else if (tableData.tableWidth === '50% and centered') return '50%';
        else return tableData.tableWidth;
    }, [tableData.tableWidth]);

    return (
        <BorderBox id={tableData.scrollAnchorId}>
            <CenteredBlock>
                <DisplayContainer>
                    {headerPosition === 'Center' ? (
                        <>
                            {isNullOrEmpty(tableData.headerSize) ? (
                                <CenteredHeader as={tableData.headerType}>{tableData.header}</CenteredHeader>
                            ) : (
                                <StyledCenteredHeaderSpot headerType={tableData.headerType} headerSize={tableData.headerSize}>
                                    {tableData.header}
                                </StyledCenteredHeaderSpot>
                            )}

                            <ContentSpotRichText
                                style={{ alignSelf: 'center', textAlign: 'center' }}
                                text={tableData.subheader}
                                width={tableData.tableWidth}
                            />
                        </>
                    ) : (
                        <>
                            <Header>{tableData.header}</Header>
                            <ContentSpotRichText text={tableData.subheader} width={tableData.tableWidth} />
                        </>
                    )}

                    <ComparisonTable tableData={tableData} />

                    {/* Import: Footnotes for desktop is rendered inside {ComparisonTable} component */}
                    {tableData.columns && tableData.columns.length > 0 && tableData.columns.some((x) => x.footnote && x.footnote.length > 0) ? (
                        <Footnotes>
                            {tableData.columns.map((col, index) => {
                                return <FootnoteText key={`col-footnote-${index}`}>{col.footnote}</FootnoteText>;
                            })}
                        </Footnotes>
                    ) : null}
                </DisplayContainer>
            </CenteredBlock>
        </BorderBox>
    );
};
