import React, { FC, useMemo } from 'react';
import { StyledDisabledOverlay, StyledStepContainer, StyledSubheader, StyledHeader } from './step.styled';
import { StyledEditButtonContainer } from '../service-agreements-step/service-agreement-step.styled';
import { Button } from '../../../shared/button';

type Prop = {
    id?: string;
    title: string;
    titleDescription?: string;
    onEditClick: () => void;
    children: React.ReactNode;
    index: number;
    currentStep: number;
    hideEdit: boolean;
    isLastStep: boolean;
    hideTitle?: boolean;
};

const Step: FC<Prop> = ({ id, index, currentStep, title, titleDescription, onEditClick, hideEdit, children, isLastStep, hideTitle }) => {
    const isOpen = useMemo(() => currentStep === index, [currentStep, index]);
    const isCompleted = useMemo(() => currentStep > index, [currentStep, index]);
    return (
        <StyledStepContainer id={id}>
            <StyledDisabledOverlay enabled={!isOpen}>
                <StyledHeader>{isOpen || !isLastStep ? title : null}</StyledHeader>
                {isOpen && !hideTitle && <StyledSubheader>{titleDescription}</StyledSubheader>}
                {children}
            </StyledDisabledOverlay>
            {isCompleted && !hideEdit && (
                <StyledEditButtonContainer>
                    <Button variant="link" onClick={onEditClick}>
                        Rediger
                    </Button>
                </StyledEditButtonContainer>
            )}
        </StyledStepContainer>
    );
};

export default Step;
