import { VFC } from 'react';
import { BorderBox, Header, Footnote } from './calculate-savings.styled';
import { SavingsResultCard } from '../savings-result-card';
import { CustomerSavingsStore } from '../../../lib/state/plus-sites/savings';
import { SavingsInputs } from '../savings-inputs/savings-inputs.components';
import { umbraco } from '../../../lib/api';
import { ReepayStore } from '../../../lib/state/reepay-form/reepay-form.store';
import { useUI } from '../../ui';

type Props = {
    closeSavings: () => void;
    showSavingsDetails: () => void;
    savingsContent?: umbraco.CustomerSavingsContent;
};

export const CalculateSavings: VFC<Props> = ({ closeSavings, savingsContent, showSavingsDetails }) => {
    const { savings, savingsResult } = CustomerSavingsStore.useStoreState((state) => state);
    const { setModalOpen } = ReepayStore.useStoreActions((actions) => actions);
    const uiHandler = useUI();

    const goToSignUp = () => {
        closeSavings();
        setModalOpen(true);
        uiHandler.removeScroll();
    };

    return (
        <BorderBox>
            <Header>{savingsContent?.header}</Header>

            <p style={{ fontSize: '14px', letterSpacing: '0', lineHeight: '20px', color: '#0b0b0b' }}>{savingsContent?.subheader}</p>

            <SavingsInputs inputs={savings} />

            <SavingsResultCard
                savings={savingsResult}
                header={savingsContent?.savingsResultHeader}
                membershipText={savingsContent?.membershipText}
                completeMemberText={savingsContent?.completeMemberText}
                calculationButtonText={savingsContent?.seeCalculationsButtonText}
                ctaText={savingsContent?.savingsResultCtaText}
                showSavingsDetails={showSavingsDetails}
                goToSignUp={goToSignUp}
            />

            <Footnote>{savingsContent?.footnote}</Footnote>
        </BorderBox>
    );
};
