import styled, { css } from 'styled-components';
import { device } from '../../../../lib/media-query';
import { DropdownInput } from '../../../forms/inputs';
import { PromotionBadges } from '../../promotion-badges/promotion-badge.component';

export const StyledOrderlineContainer = styled.div`
    &:not(:last-of-type) {
        border-bottom: 1px solid #e2e2e4;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    width: 100%;
`;

export const StyledOrderlineFlexContainer = styled.div`
    display: flex;
    gap: 25px;
`;

export const StyledOrderlineImage = styled.div<{ compressed: boolean }>`
    img {
        max-width: 75px;
        display: block;
    }

    @media ${device.mobileL} {
        img {
            max-width: ${(props) => (props.compressed ? '75px' : '115px')};
        }
    }
`;

export const StyledOrderlineInfoAndActions = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    @media ${device.tablet} {
        flex-direction: row;
    }
`;

export const StyledOrderlineLink = styled.a`
    text-decoration: none;
    color: black;
    background-color: white;
    &:hover {
        text-decoration: none;
    }
`;

export const StyledOrderlineName = styled.p<{ compressed: boolean }>`
    font-size: ${(props) => (props.compressed ? '16px' : '17px')};
    font-weight: 700;
    font-family: 'Corporate E';
    line-height: 20px;
    margin-right: 10px;
    text-decoration: none;
    margin-bottom: 5px;
`;

export const StyledPricesWrapper = styled.div`
    display: flex;
    gap: 5px;
`;

export const StyledOfferPrice = styled.p`
    font-weight: 500;
    font-size: 12px;
    color: #0089d1;
`;

export const StyledUnitPrice = styled.p<{ beforePrice: boolean }>`
    font-weight: 500;
    font-size: 12px;
    color: #aeaeaf;
    ${(props) =>
        props.beforePrice &&
        css`
            text-decoration: line-through;
        `}
`;

export const StyledActions = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media ${device.tablet} {
        margin-bottom: 0;
    }
`;

export const StyledRemoveButton = styled.button`
    border: 0;
    outline: 0;
    padding: 0;
    background-color: white;
    color: #0089d1;
    cursor: pointer;
    text-decoration: underline;
    font-size: 13px;
`;

export const StyledQuantityPicker = styled(DropdownInput)`
    min-width: 120px;

    ul {
        max-height: 252px;
    }

    li {
        padding-left: 30px;
    }
`;

export const StyledSize = styled.p`
    font-size: 12px;
    font-weight: 500;
`;

export const StyledPrice = styled.p<{ compressed: boolean }>`
    font-size: ${(props) => (props.compressed ? '14px' : '17px')};
    font-weight: 700;
    font-family: 'Corporate E';
    line-height: 20px;
    min-width: 70px;
`;

export const StyledInstallationOptionWrapper = styled.div`
    margin-top: 15px;
`;

export const StyledPromotionBadges = styled(PromotionBadges)`
    margin-bottom: 10px;
`;
