import React, { useEffect, useMemo, useState, VFC } from 'react';
import { ReepayStore } from '../../../../lib/state/reepay-form/reepay-form.store';
import { TextInput } from '../../../forms/inputs';
import {
    FormWrapper,
    FormHeader,
    InputWrapper,
    RadioLabel,
    SavingsIndicator,
    ZipInput,
    VoucherHeader,
    AnimatedInputWrapper,
    AnimatedContent,
    AgreementsWrapper,
    BorderBox,
    HeaderWrapper,
    Header,
    Subheader,
    Close,
    ModalHeaderActions,
    BackArrow,
    ReepayTermsModalContent,
    StyledApiErrorMessage,
} from './reepay-form.styled';

import { Separator } from '../../../shared/separator';
import { Button, LabelWithLinkComponent, RadioButton, SimpleModal } from '../../../shared';
import {
    cmsUrlWithoutSiteId,
    getFormattedValue,
    handleUrlClick,
    validateEmail,
    validateName,
    validateNotEmpty,
    validateRegistrationNumber,
    validateTel,
} from '../../../../utils/helpers';
import { umbraco } from '../../../../lib/api';
import { useMeasure } from 'react-use';
import { useSpring, config } from 'react-spring';
import { getAPI } from '../../../../lib/api/helpers';
import { ReepayPlan, ReepaySubscriptionResponse } from '../../../../lib/api/models/reepay';
import { CheckBox } from '../../../shared';
import { useRouter } from 'next/router';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SvgIcon } from '../../../shared/svg-icon';
import { useUI } from '../../../ui';
import { ComparisonModule } from '../../../../lib/api/models/umbraco/content-spot';
import { ComparisonModuleUi } from '../../comparison-module';
import { ReepayGtmAreas } from '../../../../lib/api/models/umbraco';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { GtmItem } from '../../../../gtm-tracking/gtm-tracking.types';
import { createAffiliation, createGtmProduct } from '../reepay-util';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { ReepayCustomer } from '../../../../lib/api/models/reepay/customer.types';
import { isSiteName, SITE_NAME } from '../../../../utils/helpers/site-id.helper';

type DawaResponse = {
    navn: string;
};
type IProps = {
    reepayFormContent: umbraco.ReepayFormContent;
    serviceComparison: ComparisonModule;
    initialValidationState?: FormValidationState;
    isFromBooking?: boolean;
};

type FormValidationState = {
    customerInfo: {
        email: boolean;
        phone: boolean;
        fullName: boolean;
        address: boolean;
        postalCode: boolean;
        city: boolean;
    };
    licensePlate: boolean;
    voucherCode: boolean;
};

enum FormSteps {
    Idle = 0,
    Agreement = 1,
    Information = 2,
    Payment = 3,
}

const getReceiptUrl = (url: string, queryParams: ReepayCustomer): URL => {
    const receiptUrl = new URL(url, window.location.origin);

    let key: keyof ReepayCustomer;
    for (key in queryParams) {
        receiptUrl.searchParams.append(key, queryParams[key]);
    }
    return receiptUrl;
};

const ReepayForm: VFC<IProps> = ({ reepayFormContent, serviceComparison, initialValidationState, isFromBooking }) => {
    const {
        modalOpen,
        customerInfo,
        voucherCode,
        agreements,
        selectedAgreement,
        selectedPaymentPlan,
        paymentSessionId,
        apiError,
        receiptUrl,
        licensePlate,
    } = ReepayStore.useStoreState((state) => state);

    const {
        setModalOpen,
        setCustomerInfo,
        validateVoucher,
        setVoucherCode,
        setSelectedAgreement,
        setSelectedPaymentPlan,
        getPlans,
        createSubscriptionSession,
        setReepaySubscription,
        resetStore,
        fullyResetStore,
        resetPaymentSessionId,
        setApiError,
        setLicensePlate,
    } = ReepayStore.useStoreActions((actions) => actions);

    const defaultHeight: string | undefined = '24px';

    const [heightRef, { height }] = useMeasure();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);
    const [currentFormStep, setCurrentFormStep] = useState(FormSteps.Idle);
    const [currentGtmProduct, setCurrentGtmProduct] = useState<GtmItem>();
    const [voucherInputIsOpen, setVoucherInputIsOpen] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const [reepayIsLoading, setReepayIsLoading] = useState(false);
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [marketingConditionsAccepted, setMarketingConditionsAccepted] = useState(false);
    const [formValidationState, setFormValidationState] = useState<FormValidationState>(
        initialValidationState ?? {
            customerInfo: {
                email: false,
                phone: false,
                fullName: false,
                address: false,
                postalCode: false,
                city: false,
            },
            licensePlate: false,
            voucherCode: false,
        }
    );
    const [canValidateState, setCanValidateState] = useState<FormValidationState>(
        initialValidationState ?? {
            customerInfo: {
                email: false,
                phone: false,
                fullName: false,
                address: false,
                postalCode: false,
                city: false,
            },
            licensePlate: false,
            voucherCode: false,
        }
    );
    const [termsOpen, setTermsOpen] = useState(false);

    const router = useRouter();

    // Animations
    const expand = useSpring({
        config: { ...config.slow, friction: 50 },
        height: voucherInputIsOpen ? `${contentHeight}px` : defaultHeight,
        overflow: !voucherInputIsOpen ? 'hidden' : '',
    });

    const fadeContent = useSpring({
        config: { ...config.slow, friction: 50 },
        opacity: voucherInputIsOpen ? 1 : 0,
        delay: 300,
    });

    useEffect(() => {
        setContentHeight(height);
    }, [height]);

    useEffect(() => {
        if (agreements) {
            getPlans(agreements);
        }
        // The plan prices are fetched async here. We only want to do this once, and the
        // getPlans updates the agreements - hence the missing agreements dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlans]);

    const uiHandler = useUI();

    const voucherValidation = async (): Promise<boolean> => {
        if (voucherCode.length > 0 && selectedPaymentPlan && selectedAgreement) {
            const voucherIsValid = await validateVoucher({
                voucher: voucherCode,
                planHandle: selectedPaymentPlan.planId,
                accountType: selectedAgreement.reepayAccountId,
            });
            return voucherIsValid;
        }
        return true;
    };

    const getVoucherToggleText = (): string => {
        let returnValue = reepayFormContent.showVoucherInputText.length > 0 ? reepayFormContent.showVoucherInputText : 'Har du en rabatkode?';
        if (voucherInputIsOpen) {
            returnValue = reepayFormContent.hideVoucherInputText.length > 0 ? reepayFormContent.hideVoucherInputText : 'Skjul rabatkode';
        }
        return returnValue;
    };

    const gtmEventType = reepayFormContent.gtmTrackAs === ReepayGtmAreas.Plus ? 'hesselPlus' : 'hesselStarPlus';

    const { trackReepayForm } = useGtmTracking();
    const tracker = trackReepayForm(gtmEventType);

    useEffect(() => {
        const getZipCity = async () => {
            if (customerInfo.postalCode.length === 4) {
                const [response, error] = await getAPI<DawaResponse>(`https://api.dataforsyningen.dk/postnumre/${customerInfo.postalCode}`);
                if (response && !error) {
                    setCustomerInfo({ ...customerInfo, city: response.navn });
                    const newFormState = formValidationState;
                    newFormState.customerInfo.city = true;
                    setFormValidationState(newFormState);
                }
            }
        };
        getZipCity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerInfo.postalCode]);

    /**
     * This useEffect is related to comparison module.
     * We want to set the selectedAgreement if the users are coming from
     * comparison module
     */
    useEffect(() => {
        if (agreements.length === 1) {
            setSelectedAgreement(agreements[0]);
        }
    }, [agreements, setSelectedAgreement]);

    /**
     * Set a default payment plan
     */
    useEffect(() => {
        if (selectedAgreement) {
            setSelectedPaymentPlan(selectedAgreement.paymentPlans[0]);
        }
    }, [selectedAgreement, setSelectedPaymentPlan]);

    //Once we have a payment session id, we initialize the Reepay checkout flow
    useEffect(() => {
        if (paymentSessionId && !document.getElementById('reepayScript')) {
            const reepayScript = document.createElement('script');
            reepayScript.setAttribute('src', 'https://checkout.reepay.com/checkout.js');
            reepayScript.id = 'reepayScript';
            document.head.appendChild(reepayScript);
            reepayScript.onload = () => {
                const rp = new window.Reepay.EmbeddedSubscription(paymentSessionId, { html_element: 'rp_container', showReceipt: false });
                rp.addEventHandler(window.Reepay.Event.Accept, function (data: ReepaySubscriptionResponse) {
                    rp.destroy();
                    setReepaySubscription(data);
                    uiHandler.toggleMobileMenu(false);
                    router.push(receiptUrl ? getReceiptUrl(receiptUrl, customerInfo) : cmsUrlWithoutSiteId(reepayFormContent.receiptPage.url));
                    setModalOpen(false);

                    resetStore({ generateReceipt: true });

                    const paymentPlan = agreements
                        .find((x) => x.reepayAccountId === selectedAgreement?.reepayAccountId)
                        ?.paymentPlans.find((x) => x.planId === selectedPaymentPlan?.planId);

                    if (selectedAgreement && paymentPlan) {
                        tracker.purchase(
                            createAffiliation(reepayFormContent.gtmTrackAs, data.customer, paymentPlan.amount),
                            createGtmProduct(reepayFormContent.gtmTrackAs, selectedAgreement, paymentPlan)
                        );
                    }

                    resetState();
                });
                setReepayIsLoading(false);
            };
        }

        return () => {
            const element = document.getElementById('reepayScript');

            if (element) {
                document.head.removeChild(element);
            }
        };
    }, [
        agreements,
        customerInfo,
        paymentSessionId,
        receiptUrl,
        reepayFormContent,
        resetStore,
        router,
        selectedAgreement,
        selectedPaymentPlan?.planId,
        setModalOpen,
        setReepaySubscription,
        tracker,
        uiHandler,
    ]);

    // We need to reset the store when URL changes, by tapping into router events
    useEffect(() => {
        const handleRouteChange = () => {
            if (isFromBooking) return; // the store is used when buying benefitAgreements from the promotional banner via booking
            fullyResetStore();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [fullyResetStore, isFromBooking, router]);

    //#region Step Handlers
    const [canScrollToTop, setCanScrollToTop] = useState(false);

    useEffect(() => {
        if (!modalOpen) {
            setCurrentFormStep(FormSteps.Idle);
        } else if (paymentSessionId) {
            setCurrentFormStep(FormSteps.Payment);
        } else if (selectedAgreement) {
            setCanScrollToTop(false);
            setCurrentFormStep(FormSteps.Information);
        } else {
            setCanScrollToTop(true);
            setCurrentFormStep(FormSteps.Agreement);
        }
    }, [modalOpen, paymentSessionId, selectedAgreement]);

    const goOneStepBack = () => {
        if (paymentSessionId) {
            resetPaymentSessionId();
        } else if (selectedAgreement) {
            setSelectedAgreement(undefined);
        }
    };

    const getBackButtonVisibility = () => {
        if (!selectedAgreement) {
            return false;
        }

        if (selectedAgreement && agreements.length > 1) {
            return true;
        }

        if (selectedAgreement && agreements.length === 1 && currentFormStep !== FormSteps.Information) {
            return true;
        }

        return false;
    };
    //#endregion

    useEffect(() => {
        const paymentPlan = agreements
            .find((x) => x.reepayAccountId === selectedAgreement?.reepayAccountId)
            ?.paymentPlans.find((x) => x.planId === selectedPaymentPlan?.planId);
        const product = selectedAgreement && paymentPlan ? createGtmProduct(reepayFormContent.gtmTrackAs, selectedAgreement, paymentPlan) : undefined;
        setCurrentGtmProduct(product);
    }, [agreements, reepayFormContent.gtmTrackAs, selectedAgreement, selectedPaymentPlan]);

    useEffect(() => {
        if (currentFormStep === FormSteps.Idle) return;

        // If we only have a single agreement,
        // the first step (Agreements) is skipped.
        const offsetCurrentStep: number = agreements.length === 1 ? currentFormStep - 1 : currentFormStep;

        /**
         * If we're switching steps from Information to Payment, we send a checkoutOption
         * event for the selected plan.
         * This event should be tracked on the Information step, hence the -1.
         */
        if (currentFormStep === FormSteps.Payment && selectedPaymentPlan) {
            tracker.checkoutOption(offsetCurrentStep - 1, selectedPaymentPlan.gtmTrackAs);
        }

        tracker.checkoutStep(offsetCurrentStep, currentGtmProduct);

        // KRF: Intentionally excluding currentGtmProduct and selectedPaymentPlan,
        // to avoid triggering when switching payment plans.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreements.length, currentFormStep, tracker]);

    useEffect(() => {
        if (apiError !== undefined) {
            setReepayIsLoading(false);
        }
    }, [apiError]);

    useEffect(() => {
        if (validateForm) {
            setCanValidateState({
                customerInfo: {
                    email: true,
                    phone: true,
                    fullName: true,
                    address: true,
                    postalCode: true,
                    city: true,
                },
                licensePlate: true,
                voucherCode: true,
            });
        }
    }, [validateForm]);

    const getSubHeaderText = (text: string): string => {
        return text.replace('{{selected_plan}}', `<strong>${selectedAgreement?.agreementName}</strong>` ?? '');
    };

    const getPaymentPlanPriceText = (plan: ReepayPlan): string => {
        const agreement = agreements.find((x) => x.reepayAccountId === selectedAgreement?.reepayAccountId);
        const aPlan = agreement?.paymentPlans.find((x) => x.planId === plan.planId);
        if (agreement && aPlan) {
            return aPlan.label.replace('{{plan_amount}}', getFormattedValue(aPlan.amount ?? 0));
        }
        return plan.label;
    };

    const getBuyButtonText = (text: string): string => {
        return text.replace('{{selected_plan}}', `${selectedAgreement?.agreementName}` ?? '');
    };

    const submitForm = async () => {
        setApiError(undefined);
        setReepayIsLoading(true);
        setValidateForm(true);
        const formValid = await isFormValid();
        if (formValid) {
            if (selectedAgreement && selectedPaymentPlan) {
                const now = new Date();

                createSubscriptionSession({
                    reepayAccountType: selectedAgreement.reepayAccountId,
                    planHandle: selectedPaymentPlan.planId,
                    voucher: voucherCode,
                    customer: customerInfo,
                    consentDetails: {
                        text: reepayFormContent.marketingTerms,
                        consent: marketingConditionsAccepted,
                        dateTime: now.toISOString(),
                        code: reepayFormContent.consentCode,
                    },
                    licensePlate,
                });
            }
        } else {
            setReepayIsLoading(false);
        }
    };

    const isFormValid = async (): Promise<boolean> => {
        const formStateValid =
            formValidationState.customerInfo.fullName &&
            formValidationState.customerInfo.address &&
            formValidationState.customerInfo.email &&
            formValidationState.customerInfo.phone &&
            formValidationState.customerInfo.postalCode &&
            formValidationState.customerInfo.city &&
            (formValidationState.licensePlate || !isSiteName(SITE_NAME.CARSAVER)) &&
            conditionsAccepted;

        if (!formStateValid) {
            return false;
        }
        const voucherIsValid = await voucherValidation();
        if (voucherIsValid) {
            return true;
        } else {
            const newFormState = formValidationState;
            newFormState.voucherCode = false;
            setFormValidationState(newFormState);
            setVoucherInputIsOpen(true);
            return false;
        }
    };

    const resetState = () => {
        setValidateForm(false);
        setMarketingConditionsAccepted(false);
        setConditionsAccepted(false);
    };

    const apiErrorMessageToDisplay = useMemo(() => {
        const defaultErrorMessage = 'Der skete desværre en fejl. Prøv igen senere.';
        if (apiError) {
            const error = reepayFormContent.errorMessages.find((x) => x.apiErrorCode === apiError.errorType);
            return error?.errorMessageInFrontend ?? defaultErrorMessage;
        }
        return defaultErrorMessage;
    }, [apiError, reepayFormContent.errorMessages]);

    const cancelFunction = () => {
        if (termsOpen) {
            setTermsOpen(false);
        } else {
            setModalOpen(false);
            resetStore({ generateReceipt: false });
            setCanValidateState({
                customerInfo: {
                    email: false,
                    phone: false,
                    fullName: false,
                    address: false,
                    postalCode: false,
                    city: false,
                },
                licensePlate: false,
                voucherCode: false,
            });
            setFormValidationState(
                initialValidationState ?? {
                    customerInfo: {
                        email: false,
                        phone: false,
                        fullName: false,
                        address: false,
                        postalCode: false,
                        city: false,
                    },
                    licensePlate: false,
                    voucherCode: false,
                }
            );
            setValidateForm(false);
            setVoucherInputIsOpen(false);
            setVoucherCode('');
            setApiError(undefined);
            setConditionsAccepted(false);
            setMarketingConditionsAccepted(false);
            uiHandler.applyScroll();
        }
    };

    return (
        <SidePanel
            isVisible={modalOpen}
            cancelAction={() => {
                cancelFunction();
            }}
            scrollToTop={canScrollToTop}
            variant={selectedAgreement ? 'md' : 'lg'}
        >
            <BorderBox>
                <SimpleModal
                    headerText={reepayFormContent.consentModalHeader}
                    isVisible={termsOpen}
                    closeAction={() => {
                        setTermsOpen(false);
                        uiHandler.applyScroll();
                    }}
                    closeText={reepayFormContent.consentModalButtonText}
                >
                    <ReepayTermsModalContent
                        dangerouslySetInnerHTML={{
                            __html: reepayFormContent.marketingTerms.replace('/media/', `${MEDIA_URL}/media/`),
                        }}
                    />
                </SimpleModal>

                <ModalHeaderActions>
                    {getBackButtonVisibility() && (
                        <BackArrow onClick={() => goOneStepBack()}>
                            <SvgIcon iconName={'arrow/left'} />
                            <span style={{ marginLeft: '0.5rem' }}>
                                {reepayFormContent.goBackText && reepayFormContent.goBackText.length > 0 ? reepayFormContent.goBackText : 'Tilbage'}
                            </span>
                        </BackArrow>
                    )}
                    <Close
                        onClick={() => {
                            cancelFunction();
                        }}
                    >
                        <SvgIcon iconName={'close'} />
                    </Close>
                </ModalHeaderActions>
                {selectedAgreement && (
                    <>
                        <HeaderWrapper>
                            <Header>{reepayFormContent.formHeader}</Header>
                            <Subheader dangerouslySetInnerHTML={{ __html: getSubHeaderText(reepayFormContent.formSubheader) }} />
                        </HeaderWrapper>
                        <FormWrapper isLoading={reepayIsLoading}>
                            {paymentSessionId ? (
                                <>
                                    <div id="rp_container" style={{ width: '100%', height: 'calc(100vh - 200px)' }}></div>
                                </>
                            ) : (
                                <>
                                    <FormHeader>{reepayFormContent.informationHeader}</FormHeader>
                                    {/* Name */}
                                    <InputWrapper>
                                        <TextInput
                                            id="reepayCustomerName"
                                            label={reepayFormContent.nameLabel}
                                            type="text"
                                            onChange={(e) => {
                                                setCustomerInfo({ ...customerInfo, fullName: e.target.value });
                                                const newFormState = formValidationState;
                                                newFormState.customerInfo.fullName = validateName(e.target.value);
                                                setFormValidationState(newFormState);
                                            }}
                                            isValid={formValidationState.customerInfo.fullName}
                                            canValidateInputField={canValidateState.customerInfo.fullName}
                                            value={customerInfo.fullName}
                                            placeholder={reepayFormContent.namePlaceholder}
                                            validationMessage={reepayFormContent.nameValidationMessage}
                                            onInputBlur={() => {
                                                setCanValidateState({
                                                    ...canValidateState,
                                                    customerInfo: { ...canValidateState.customerInfo, fullName: true },
                                                });
                                            }}
                                        ></TextInput>
                                    </InputWrapper>

                                    {/* Address */}
                                    <InputWrapper>
                                        <TextInput
                                            id="reepayCustomerAddress"
                                            label={reepayFormContent.addressLabel}
                                            type="text"
                                            onChange={(e) => {
                                                setCustomerInfo({ ...customerInfo, address: e.target.value });
                                                const newFormState = formValidationState;
                                                newFormState.customerInfo.address = validateNotEmpty(e.target.value);
                                                setFormValidationState(newFormState);
                                            }}
                                            isValid={validateNotEmpty(customerInfo.address)}
                                            canValidateInputField={canValidateState.customerInfo.address}
                                            value={customerInfo.address}
                                            placeholder={reepayFormContent.addressPlaceholder}
                                            validationMessage={reepayFormContent.addressValidationMessage}
                                            onInputBlur={() => {
                                                setCanValidateState({
                                                    ...canValidateState,
                                                    customerInfo: { ...canValidateState.customerInfo, address: true },
                                                });
                                            }}
                                        ></TextInput>
                                    </InputWrapper>

                                    {/* Zipcode & City */}
                                    <InputWrapper>
                                        <ZipInput>
                                            <TextInput
                                                id="reepayCustomerZip"
                                                label={reepayFormContent.zipcodeLabel}
                                                onChange={(e) => {
                                                    setCustomerInfo({ ...customerInfo, postalCode: e.target.value });
                                                    const newFormState = formValidationState;
                                                    newFormState.customerInfo.postalCode = e.target.value.length === 4;
                                                    setFormValidationState(newFormState);
                                                }}
                                                type="text"
                                                isValid={formValidationState.customerInfo.postalCode}
                                                canValidateInputField={canValidateState.customerInfo.postalCode}
                                                value={customerInfo.postalCode}
                                                placeholder={reepayFormContent.zipcodePlaceholder}
                                                validationMessage={reepayFormContent.zipcodeValidationMessage}
                                                pattern="[0-9]*"
                                                onInputBlur={() => {
                                                    setCanValidateState({
                                                        ...canValidateState,
                                                        customerInfo: { ...canValidateState.customerInfo, postalCode: true },
                                                    });
                                                }}
                                            />
                                            <TextInput
                                                id="reepayCustomerCity"
                                                label={reepayFormContent.cityLabel}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setCustomerInfo({ ...customerInfo, city: value });
                                                    const newFormState = formValidationState;
                                                    newFormState.customerInfo.city = validateNotEmpty(value);
                                                    setFormValidationState(newFormState);
                                                }}
                                                type="text"
                                                isValid={formValidationState.customerInfo.city}
                                                canValidateInputField={canValidateState.customerInfo.postalCode}
                                                value={customerInfo.city}
                                                placeholder={reepayFormContent.cityPlaceholder}
                                                validationMessage={reepayFormContent.cityValidationMessage}
                                                onInputBlur={() => {
                                                    setCanValidateState({
                                                        ...canValidateState,
                                                        customerInfo: { ...canValidateState.customerInfo, city: true },
                                                    });
                                                }}
                                            />
                                        </ZipInput>
                                    </InputWrapper>

                                    {/* Email */}
                                    <InputWrapper>
                                        <TextInput
                                            id="reepayCustomerEmail"
                                            label={reepayFormContent.emailLabel}
                                            type="email"
                                            onChange={(e) => {
                                                setCustomerInfo({ ...customerInfo, email: e.target.value });
                                                const newFormState = formValidationState;
                                                newFormState.customerInfo.email = validateEmail(e.target.value);
                                                setFormValidationState(newFormState);
                                            }}
                                            isValid={formValidationState.customerInfo.email}
                                            canValidateInputField={canValidateState.customerInfo.email}
                                            value={customerInfo.email}
                                            placeholder={reepayFormContent.emailPlaceholder}
                                            validationMessage={reepayFormContent.emailValidationMessage}
                                            onInputBlur={() => {
                                                setCanValidateState({
                                                    ...canValidateState,
                                                    customerInfo: { ...canValidateState.customerInfo, email: true },
                                                });
                                            }}
                                        ></TextInput>
                                    </InputWrapper>

                                    {/* Telephone */}
                                    <InputWrapper>
                                        <TextInput
                                            id="reepayCustomerPhone"
                                            label={reepayFormContent.phoneLabel}
                                            type="tel"
                                            onChange={(e) => {
                                                setCustomerInfo({ ...customerInfo, phone: e.target.value });
                                                const newFormState = formValidationState;
                                                newFormState.customerInfo.phone = validateTel(e.target.value);
                                                setFormValidationState(newFormState);
                                            }}
                                            isValid={formValidationState.customerInfo.phone}
                                            canValidateInputField={canValidateState.customerInfo.phone}
                                            value={customerInfo.phone}
                                            placeholder={reepayFormContent.phonePlaceholder}
                                            validationMessage={reepayFormContent.phoneValidationMessage}
                                            onInputBlur={() => {
                                                setCanValidateState({
                                                    ...canValidateState,
                                                    customerInfo: { ...canValidateState.customerInfo, phone: true },
                                                });
                                            }}
                                        ></TextInput>
                                    </InputWrapper>
                                    {isSiteName(SITE_NAME.CARSAVER) && (
                                        <InputWrapper>
                                            <TextInput
                                                id="reepayCustomerLicensePlate"
                                                label={reepayFormContent.licensePlateLabel}
                                                type="text"
                                                onChange={(e) => {
                                                    setLicensePlate(e.target.value);
                                                    const newFormState = formValidationState;
                                                    newFormState.licensePlate = validateRegistrationNumber(e.target.value, true);
                                                    setFormValidationState(newFormState);
                                                }}
                                                isValid={formValidationState.licensePlate}
                                                canValidateInputField={canValidateState.licensePlate}
                                                value={licensePlate}
                                                placeholder={reepayFormContent.licensePlatePlaceholder}
                                                validationMessage={reepayFormContent.licensePlateValidationMessage}
                                                onInputBlur={() => {
                                                    setCanValidateState({
                                                        ...canValidateState,
                                                        licensePlate: true,
                                                    });
                                                }}
                                            ></TextInput>
                                        </InputWrapper>
                                    )}
                                    <AnimatedInputWrapper style={expand}>
                                        <div ref={heightRef as any}>
                                            <VoucherHeader onClick={() => setVoucherInputIsOpen(!voucherInputIsOpen)}>
                                                {getVoucherToggleText()}
                                            </VoucherHeader>
                                            <AnimatedContent style={fadeContent}>
                                                <TextInput
                                                    id="reepayVoucher"
                                                    label={reepayFormContent.voucherCodeLabel}
                                                    disabled={!voucherInputIsOpen}
                                                    type="text"
                                                    onChange={(e) => {
                                                        setVoucherCode(e.target.value);
                                                        const newFormState = formValidationState;
                                                        newFormState.voucherCode = true;
                                                        setFormValidationState(newFormState);
                                                    }}
                                                    isValid={formValidationState.voucherCode}
                                                    canValidateInputField={canValidateState.voucherCode && voucherCode.length > 0}
                                                    value={voucherCode}
                                                    placeholder={reepayFormContent.voucherCodePlaceholder}
                                                    validationMessage={reepayFormContent.invalidVoucherMessage}
                                                    onInputBlur={() => {
                                                        setCanValidateState({
                                                            ...canValidateState,
                                                            voucherCode: true,
                                                        });
                                                    }}
                                                ></TextInput>
                                            </AnimatedContent>
                                        </div>
                                    </AnimatedInputWrapper>

                                    <AgreementsWrapper>
                                        <FormHeader>{reepayFormContent.paymentPlanHeader}</FormHeader>
                                        {selectedAgreement?.paymentPlans.map((x) => {
                                            return (
                                                <InputWrapper key={x.planId}>
                                                    <RadioButton
                                                        id={`paymentPlan-${x.planId}`}
                                                        checked={selectedPaymentPlan === x}
                                                        value={x.planId}
                                                        labelAlignment={x.savingsText ? 'top' : 'center'}
                                                        action={() => setSelectedPaymentPlan(x)}
                                                        groupName="paymentPlan"
                                                        required={true}
                                                        isValid={selectedPaymentPlan !== undefined}
                                                    >
                                                        <RadioLabel>{getPaymentPlanPriceText(x)}</RadioLabel>
                                                        {x.savingsText && <SavingsIndicator>{x.savingsText}</SavingsIndicator>}
                                                    </RadioButton>
                                                </InputWrapper>
                                            );
                                        })}
                                    </AgreementsWrapper>

                                    <Separator marginTop="30px" marginBottom="30px"></Separator>

                                    <InputWrapper>
                                        <CheckBox
                                            id="terms"
                                            value="1"
                                            checked={conditionsAccepted}
                                            onChange={(checked) => {
                                                setConditionsAccepted(checked);
                                            }}
                                            isValid={!validateForm || conditionsAccepted}
                                            required={true}
                                            validationMessage={reepayFormContent.gdprPolicyError}
                                            textAlign="top"
                                        >
                                            <LabelWithLinkComponent
                                                labelText={reepayFormContent.gdprPolicyLabel}
                                                termsLinkText={reepayFormContent.gdprTermsLinkText}
                                                onTermsLinkClick={() => {
                                                    handleUrlClick(reepayFormContent.privacyPolicyUrl);
                                                }}
                                            />
                                        </CheckBox>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <CheckBox
                                            id="marketingTerms"
                                            value="1"
                                            checked={marketingConditionsAccepted}
                                            onChange={(checked) => {
                                                setMarketingConditionsAccepted(checked);
                                            }}
                                            textAlign="top"
                                            isValid={!validateForm || marketingConditionsAccepted}
                                            validationMessage={reepayFormContent.marketingPermissionValidationMessage}
                                        >
                                            <LabelWithLinkComponent
                                                labelText={reepayFormContent.marketingPermissionLabel}
                                                termsLinkText={reepayFormContent.marketingTermsLinkText}
                                                onTermsLinkClick={() => {
                                                    uiHandler.removeScroll();
                                                    setTermsOpen(true);
                                                }}
                                            />
                                        </CheckBox>
                                    </InputWrapper>
                                </>
                            )}
                        </FormWrapper>
                    </>
                )}
                {!selectedAgreement && serviceComparison ? <ComparisonModuleUi tableData={serviceComparison} /> : null}

                {paymentSessionId === undefined && selectedAgreement ? (
                    <div>
                        <Button alignSelf="left" onClick={() => submitForm()} variant="primary">
                            {getBuyButtonText(reepayFormContent.goToPaymentButtonText)}
                        </Button>
                    </div>
                ) : null}
                {apiError !== undefined ? <StyledApiErrorMessage>{apiErrorMessageToDisplay}</StyledApiErrorMessage> : null}
            </BorderBox>
        </SidePanel>
    );
};

export default ReepayForm;
