import { parseISO } from 'date-fns';
import React, { VFC } from 'react';
import { DateStyle, formatDate } from '../../../../utils/helpers';

import { StyledAccordionHeader, HeaderLabel, OpeningInfoWrapper, OpeningStatus, OpeningStatusText, Hours, StyledText } from './header.styled';
import { SpecialDay, OpeningHour } from '../../../../lib/api/models/umbraco/organization.types';

export type OpeningHoursHeaderProps = {
    className?: string;
    label: string;
    isOpen: boolean;
    openingHour?: OpeningHour | SpecialDay;
};

const OpeningHoursHeaderMemo: VFC<OpeningHoursHeaderProps> = ({ className, label, isOpen, openingHour }) => {
    const ClosingText = () => {
        if(openingHour?.closed){
            return 'Lukket';
        }

        if(openingHour?.openByAppointment){
            return 'Åben efter aftale';
        }
    }

    return openingHour ? (
        <StyledAccordionHeader className={className}>
            <HeaderLabel>{label}</HeaderLabel>
            <OpeningInfoWrapper>
                {isOpen && (
                    <OpeningStatus isOpen={isOpen}>
                        <OpeningStatusText>Åbent nu</OpeningStatusText>
                    </OpeningStatus>
                )}
                <Hours>
                    {isOpen ? (
                        <>
                            I dag&nbsp;{formatDate(parseISO(openingHour.from), DateStyle.HH_mm)}-
                            {formatDate(parseISO(openingHour.to), DateStyle.HH_mm)}
                        </>
                    ) : (
                        <StyledText>{ClosingText()}</StyledText>
                    )}
                </Hours>
            </OpeningInfoWrapper>
        </StyledAccordionHeader>
    ) : null;
};

export const OpeningHoursAccordionHeader = React.memo(OpeningHoursHeaderMemo);
