import { FC, useEffect, useState } from 'react';
import {
    StyledAddVoucherWrapper,
    StyledErrorMessage,
    StyledInputAndCtaWrapper,
    StyledVoucherToggleText,
    StyledVoucherToggleWrapper,
} from './add-voucher.styled';
import { ReadMoreWrapper } from '../../../shared/read-more/read-more-wrapper.component';
import { SvgIcon } from '../../../shared/svg-icon';
import { TextInput } from '../../../forms/inputs';
import { Button } from '../../../shared';
import { CartStoreContext } from '../../../../lib/state/shop/cart/cart.store';

export const AddVoucher: FC = () => {
    const [isVoucherOpen, setIsVoucherOpen] = useState(false);
    const [voucherCode, setVoucherCode] = useState('');
    const { cart, voucherMessage } = CartStoreContext.useStoreState((state) => state);
    const { addVoucherToCart, setVoucherMessage } = CartStoreContext.useStoreActions((actions) => actions);

    const submitVoucher = () => {
        if (cart !== null) {
            addVoucherToCart({ cart, voucherCode });
        }
    };

    useEffect(() => {
        if (!isVoucherOpen) {
            setVoucherCode('');
            setVoucherMessage(null);
        }
    }, [isVoucherOpen, setVoucherMessage]);

    useEffect(() => {
        if (voucherMessage && voucherMessage.success) {
            setVoucherCode('');
        }
    }, [voucherMessage]);

    return (
        <StyledAddVoucherWrapper>
            <ReadMoreWrapper
                labelContent={
                    <StyledVoucherToggleWrapper>
                        <div
                            style={
                                isVoucherOpen
                                    ? { transform: 'rotate(180deg)', transition: 'all .2s ease-in-out' }
                                    : { transition: 'all .2s ease-in-out' }
                            }
                        >
                            <SvgIcon iconName={`chevron/down`} />
                        </div>
                        <StyledVoucherToggleText>{`Tilføj rabatkode (valgtfrit)`}</StyledVoucherToggleText>
                    </StyledVoucherToggleWrapper>
                }
                contentBelowToggle={true}
                echoNewState={(newState) => setIsVoucherOpen(newState)}
            >
                <StyledInputAndCtaWrapper>
                    <TextInput
                        id={'voucher-code-input'}
                        label={'Rabatkode'}
                        type="text"
                        onChange={(e) => {
                            setVoucherCode(e.target.value);
                            setVoucherMessage(null);
                        }}
                        isValid={false}
                        canValidateInputField={false}
                        value={voucherCode}
                    />
                    <Button onClick={() => submitVoucher()} variant="primary">
                        TILFØJ
                    </Button>
                </StyledInputAndCtaWrapper>
                {voucherMessage && voucherMessage.message.length > 0 ? <StyledErrorMessage>{voucherMessage.message}</StyledErrorMessage> : null}
            </ReadMoreWrapper>
        </StyledAddVoucherWrapper>
    );
};
