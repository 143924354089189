import styled, { css } from 'styled-components';

export const StyledFooter = styled.footer<{ carsaver: boolean; marginTop?: string }>`
    background-color: ${({ theme }) => theme.palette.footer.background};
    color: ${({ theme }) => theme.palette.footer.color};
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    line-height: 16px;
    padding: ${(props) => (props.carsaver ? '64px 1.25rem' : '2.875rem 1.25rem')};
    position: relative;
    width: 100%;
    ${(props) =>
        props.marginTop &&
        props.marginTop.length > 0 &&
        css`
            margin-top: ${props.marginTop};
        `}
`;

export const InfoSection = styled.section`
    margin-bottom: 3.125rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
`;

export const CustomerInfoMobile = styled.section`
    display: flex;
    flex-direction: column;
`;

export const MasterData = styled.section``;

export const OpeningHoursWrapper = styled.section`
    margin: 1.5rem 0;
`;

export const OpeningHoursContent = styled.div<{ isCarSaver: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${({ isCarSaver }) =>
        isCarSaver
            ? css`
                  gap: 15px;
              `
            : css`
                  width: 175px;
              `}
`;

export const PaymentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${({ theme: { typography } }) => typography.footer.fontSize}px;
`;

export const CreditsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 200px;
`;

export const CreditCard = styled.div`
    width: 40px;
    height: 40px;

    & img {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const CtaHandlerWrapperMobile = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
