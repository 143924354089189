import { useRef, VFC, useEffect, useState } from 'react';

import {
    StyledButton,
    StyledDealershipMap,
    StyledErrorMessage,
    StyledGeoInputFilterWrapper,
    StyledInputAndErrorWrapper,
    StyledListMapToggle,
    StyledMapAndListContainer,
} from './carsaver-map.styled';
import { CarSaverMapMarkers } from '../carsaver-map-markers/carsaver-map-markers.component';
import { CarSaverWorkshop } from '../../../../lib/api/organization/carsaver-dealership-api';
import { CarSaverMapStyles } from './carsaver-map-styles';
import { GeoFilterInput } from '../../../organization/geo-filter-input/geo-filter-input.component';
import { cities_and_geo_location } from '../../../organization/cities';
import { calculateDistance } from '../../../../utils/helpers/geo-location.helpers';
import { CarSaverWorkshopsList } from '../carsaver-workshops-list/carsaver-workshops-list.component';

export type MapProps = {
    center: google.maps.LatLngLiteral;
    zoom: number;
    workshops: CarSaverWorkshop[];
};

export const CarSaverMap: VFC<MapProps> = ({ center, zoom, workshops }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [userSearch, setUserSearch] = useState('');
    const [hideList, setHideList] = useState(false);
    const [workshopsSortedByDistance, setWorkshopsSortedByDistance] = useState<CarSaverWorkshop[]>(workshops);
    const [searchError, setSearchError] = useState(false);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center,
                    zoom,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    gestureHandling: 'cooperates',
                    styles: CarSaverMapStyles,
                })
            );
        }
    }, [ref, map, center, zoom]);

    const sortWorkshopsByDistance = (lat: number, lng: number) => {
        const sorted = workshops.sort((a, b) => {
            return calculateDistance(a.latitude, a.longitude, lat, lng) - calculateDistance(b.latitude, b.longitude, lat, lng);
        });
        setWorkshopsSortedByDistance([...sorted]);
        setSearchError(false);
        return sorted;
    };

    const applySearch = () => {
        if (userSearch.length > 0 && map) {
            if (userSearch.length === 4 && !isNaN(Number(userSearch))) {
                const location = cities_and_geo_location.find((x) => x.zipcode === userSearch);
                if (location) {
                    const workshopsSorted = sortWorkshopsByDistance(location.coordinates.latitude, location.coordinates.longitude);
                    if (workshopsSorted.length > 0) {
                        map.setCenter({
                            lat: workshopsSorted[0].latitude,
                            lng: workshopsSorted[0].longitude,
                        });
                        map.setZoom(14);
                    } else {
                        setSearchError(true);
                    }
                }
            } else {
                const spellingMethods = [
                    userSearch.toLowerCase(),
                    userSearch.toLowerCase().replace('aa', 'å'),
                    userSearch.toLowerCase().replace('å', 'aa'),
                ];
                let matchedLocations = cities_and_geo_location.filter((x) => spellingMethods.some((y) => y === x.name.toLowerCase()));
                if (!matchedLocations || matchedLocations.length === 0) {
                    matchedLocations = cities_and_geo_location.filter((x) => spellingMethods.some((y) => x.name.toLowerCase().includes(y)));
                }
                if (!matchedLocations || matchedLocations.length === 0) {
                    const locationMatcher = workshops.find((x) =>
                        spellingMethods.some(
                            (y) => x.name.toLowerCase().includes(y) || x.address.toLowerCase().includes(y) || x.city.toLowerCase().includes(y)
                        )
                    );
                    if (locationMatcher) {
                        matchedLocations = [
                            {
                                coordinates: {
                                    latitude: locationMatcher.latitude,
                                    longitude: locationMatcher.longitude,
                                },
                                name: '',
                                zipcode: '',
                            },
                        ];
                    }
                }

                if (matchedLocations && matchedLocations.length > 0) {
                    const location = matchedLocations[0];
                    const workshopsSorted = sortWorkshopsByDistance(location.coordinates.latitude, location.coordinates.longitude);
                    if (workshopsSorted.length > 0) {
                        map.setCenter({
                            lat: workshopsSorted[0].latitude,
                            lng: workshopsSorted[0].longitude,
                        });
                        map.setZoom(14);
                    }
                } else {
                    setSearchError(true);
                }
            }
        }
    };

    const getUserPosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const workshopsSorted = sortWorkshopsByDistance(position.coords.latitude, position.coords.longitude);
                    if (workshopsSorted.length > 0 && map) {
                        map.setCenter({
                            lat: workshopsSorted[0].latitude,
                            lng: workshopsSorted[0].longitude,
                        });
                        map.setZoom(14);
                    }
                },
                (_error) => {},
                {
                    enableHighAccuracy: true,
                    timeout: 60000,
                }
            );
        }
    };

    useEffect(() => {
        getUserPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    return (
        <>
            <StyledInputAndErrorWrapper>
                <StyledGeoInputFilterWrapper
                    onSubmit={(e) => {
                        applySearch();
                        e.preventDefault();
                    }}
                >
                    <GeoFilterInput
                        id="dealership-geo-filter-input"
                        label="Søg"
                        onChange={(e) => {
                            setUserSearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Post nr. eller by"
                        value={userSearch}
                        disabled={false}
                        isValid={false}
                        canValidateInputField={false}
                        requestForLocation={() => {
                            getUserPosition();
                        }}
                    />
                    <StyledButton type="submit" variant="primary" onClick={() => null} unsetMinWidth={true}>
                        Søg
                    </StyledButton>
                </StyledGeoInputFilterWrapper>
                {searchError && <StyledErrorMessage>Din søgning gav desværre ingen resultater</StyledErrorMessage>}
            </StyledInputAndErrorWrapper>
            <StyledListMapToggle onClick={() => setHideList(!hideList)}>
                <p>{hideList ? 'Se på liste' : 'Se på kort'}</p>
            </StyledListMapToggle>
            <StyledMapAndListContainer>
                <CarSaverWorkshopsList workshops={workshopsSortedByDistance} hideList={hideList} />
                <StyledDealershipMap ref={ref} />
            </StyledMapAndListContainer>
            <CarSaverMapMarkers
                workshops={workshops}
                map={{
                    map,
                    position: center,
                }}
            />
        </>
    );
};
