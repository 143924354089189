import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSearchResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 465px;
    overflow: auto;
`;

export const StyledSearchResult = styled.a`
    display: flex;
    padding: 4px;
    background-color: white;
    color: #0b0b0b;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;

export const StyledSearchResultImageContainer = styled.div`
    background-color: #f7f7f8;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    height: 100px;
`;

export const StyledSearchResultImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

export const StyledSearchResultInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    flex-grow: 1;

    @media ${device.mobile_tablet} {
        padding: 20px;
        gap: 10px;
    }

    @media ${device.laptop} {
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
    }
`;

export const StyledVehicleNameAndFamilyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media ${device.mobile_tablet} {
        gap: 8px;
    }
`;

export const StyledSpecsContainter = styled.div`
    display: none;
    @media ${device.laptopS} {
        display: flex;
        gap: 8px;
    }
`;

export const StyledSpecsRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
        font-size: 10px;
        line-height: 12px;
    }
`;

export const StyledSpecsValue = styled.span`
    font-weight: 600;
`;

export const StyledBrandModelText = styled.p`
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
`;

export const StyledVehicleName = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    @media ${device.mobile_tablet} {
        font-size: 16px;
    }
    @media ${device.laptopS} {
        width: 226px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 19px;
    }
`;

export const StyledVehiclePricesContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.laptop} {
        align-items: flex-end;
        margin-left: auto;
    }
`;

export const StyledPriceAndLabelContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 10px;
`;

export const StyledPriceValue = styled.p`
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
`;

export const StyledFinancePrice = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #767676;
`;

export const StyledPriceLabel = styled.p`
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    color: #767676;
    height: 14px;
    display: flex;
    align-items: flex-end;
`;

export const StyledColorContainer = styled.div`
    display: none;
    @media ${device.laptop} {
        display: flex;
        gap: 5px;
    }
`;

export const StyledColorPreview = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #949494;
    background-position: center center;
`;

export const StyledDesktopOnlyWrapper = styled.div`
    display: none;
    @media ${device.laptop} {
        display: block;
    }
`;

export const StyledResultTypeHeader = styled.p`
    font-weight: 700;
    color: #767676;
    font-family: 'Corporate E';
    font-size: 18px;
    margin-bottom: 10px;
`;

export const StyledContentResultHeader = styled.p`
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
`;

export const StyledContentResultText = styled.p`
    font-size: 12px;
`;

export const StyledAvailabilityTile = styled.div<{ availability: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    padding: 0 7px;
    height: 18px;
    background-color: #00adef;
    color: #0b0b0b;
    ${(props) =>
        props.availability === 's' &&
        css`
            background-color: #0b0b0b;
            color: white;
        `}
    ${(props) =>
        props.availability === 'o' &&
        css`
            background-color: #688488;
            color: white;
        `}
`;

export const StyledContentResult = styled.a`
    text-decoration: none;
    color: black;
    display: block;
    padding: 15px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px; */

    &:hover {
        text-decoration: none;
    }
`;

export const StyledLoadMoreButonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 0;
`;

export const StyledDragSliderWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const StyledMobileCarouselWrapper = styled.div`
    display: block;
    @media ${device.tablet} {
        display: none;
    }
`;

export const StyledTabletCarouselWrapper = styled.div`
    display: none;
    @media ${device.tablet} {
        display: block;
    }
    @media ${device.laptopS} {
        display: none;
    }
`;

export const StyledDesktopCarouselWrapper = styled.div`
    display: none;
    @media ${device.laptopS} {
        display: block;
    }
`;

export const StyledWebshopProductWrapper = styled.div`
    display: grid;
    gap: 20px;
    margin-bottom: 10px;

    @media ${device.mobile_tablet} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${device.tablet} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${device.laptop} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${device.laptopS} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const StyledWebshopSection = styled.div`
    max-height: 750px;
    overflow: auto;
`;
