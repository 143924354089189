import styled from 'styled-components';
import { device } from '../../../../lib/media-query';

export const StyledFilterWrapper = styled.aside`
    display: none;
    flex-direction: column;
    z-index: 1;
    @media ${device.laptopS} {
        display: flex;
    }
`;

export const StyledFilterContainer = styled.div`
    background-color: white;
    padding: 10px;
    box-shadow: ${({ theme: { shadows } }) => shadows.large};
`;
