import Link from 'next/link';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { MegaMenu, TopLevelLink } from '../../../lib/api/models/umbraco/mega-menu.types';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { InspirationalLink } from '../inspirational-link/inspirational-link.compontent';
import { PrimaryLink } from '../primary-link/primary-link.component';
import { SecondaryLinkSection } from '../secondary-link-section/secondary-link-section.component';
import {
    StyledFadeWrapper,
    StyledInspirationalLinkWrapper,
    StyledItemWrapper,
    StyledMegaMenuContentGrid,
    StyledMegaMenuContentWrapper,
    StyledMenuItem,
    StyledPrimaryLinkHeader,
    StyledPrimaryLinkWrapper,
    StyledPrimaryMenuArea,
    StyledSecondaryMenuWrapper,
} from './top-level-item.styled';

type IProps = {
    link: TopLevelLink;
    megaMenuContent?: MegaMenu;
    onMegaMenuChange: (newState: boolean) => void;
};

export const TopLevelItem: FC<IProps> = ({ link, megaMenuContent, onMegaMenuChange }) => {
    const OPEN_MEGA_MENU_Z_INDEX = 14;
    const CLOSED_MEGA_MENU_Z_INDEX = 5;
    const [megaMenuOpen, setMegaMenuOpen] = useState(false);

    const defaultHeight: string | undefined = '0px';

    const [heightRef, { height }] = useMeasure<HTMLDivElement>();

    const [contentHeight, setContentHeight] = useState(defaultHeight || height);

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
    }, [height]);

    const expand = useSpring({
        height: megaMenuOpen ? `${Number(contentHeight) + 20}px` : defaultHeight,
        overflow: !megaMenuOpen ? 'hidden' : '',
        delay: 0,
    });

    const fadeContent = useSpring({
        opacity: megaMenuOpen ? 1 : 0,
        paddingTop: '30px',
        delay: megaMenuOpen ? 300 : 0,
    });

    const indexesWithoutBorder: number[] = useMemo(() => {
        if (megaMenuContent) {
            const length = megaMenuContent.primaryLinks.length;
            if (length % 2 === 0) {
                return [length - 1, length - 2];
            } else {
                return [length - 1];
            }
        }
        return [];
    }, [megaMenuContent]);

    const mouseEnterEvent = () => {
        setMegaMenuOpen(true);
        if (megaMenuContent) {
            onMegaMenuChange(true);
        }
    };

    const mouseLeaveEvent = () => {
        setMegaMenuOpen(false);
        if (megaMenuContent) {
            onMegaMenuChange(false);
        }
    };

    return (
        <StyledItemWrapper onMouseEnter={() => mouseEnterEvent()} onMouseLeave={() => mouseLeaveEvent()}>
            <Link href={cmsUrlWithoutSiteId(link.linkDestination.url)} passHref>
                <StyledMenuItem megaMenuOpen={megaMenuOpen} onClick={() => mouseLeaveEvent()} target={link.linkDestination.target}>
                    {link.linkText}
                </StyledMenuItem>
            </Link>
            {megaMenuContent ? (
                <StyledMegaMenuContentWrapper
                    style={{
                        ...expand,
                        zIndex: megaMenuOpen ? OPEN_MEGA_MENU_Z_INDEX : CLOSED_MEGA_MENU_Z_INDEX,
                        borderBottom: megaMenuOpen ? '1px solid #f7f7f8' : 'none',
                    }}
                >
                    <div ref={heightRef}>
                        <CenteredBlock>
                            <StyledFadeWrapper style={fadeContent}>
                                <StyledMegaMenuContentGrid>
                                    <StyledPrimaryMenuArea>
                                        {megaMenuContent.inspirationHeader.length > 0 ? (
                                            <StyledPrimaryLinkHeader>{megaMenuContent.inspirationHeader}</StyledPrimaryLinkHeader>
                                        ) : null}
                                        <StyledInspirationalLinkWrapper gridItems={megaMenuContent.inspirationalLinks.length === 5 ? 5 : 4}>
                                            {megaMenuContent.inspirationalLinks.map((x, idx) => {
                                                return (
                                                    <InspirationalLink onLinkClick={() => mouseLeaveEvent()} link={x} key={idx}></InspirationalLink>
                                                );
                                            })}
                                        </StyledInspirationalLinkWrapper>
                                        <StyledPrimaryLinkWrapper>
                                            {megaMenuContent.primaryLinks.map((x, idx) => {
                                                return (
                                                    <PrimaryLink
                                                        onLinkClick={() => mouseLeaveEvent()}
                                                        link={x}
                                                        key={idx}
                                                        border={indexesWithoutBorder.indexOf(idx) === -1}
                                                    ></PrimaryLink>
                                                );
                                            })}
                                        </StyledPrimaryLinkWrapper>
                                    </StyledPrimaryMenuArea>
                                    {megaMenuContent.secondaryLinkSections.length > 0 ? (
                                        <StyledSecondaryMenuWrapper>
                                            {megaMenuContent.secondaryLinkSections.map((x, idx) => {
                                                return (
                                                    <SecondaryLinkSection
                                                        onLinkClick={() => mouseLeaveEvent()}
                                                        content={x}
                                                        key={idx}
                                                    ></SecondaryLinkSection>
                                                );
                                            })}
                                        </StyledSecondaryMenuWrapper>
                                    ) : (
                                        <div />
                                    )}
                                </StyledMegaMenuContentGrid>
                            </StyledFadeWrapper>
                        </CenteredBlock>
                    </div>
                </StyledMegaMenuContentWrapper>
            ) : null}
        </StyledItemWrapper>
    );
};
