import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { isSiteName, SITE_NAME } from '../../../../utils/helpers/site-id.helper';

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
    margin-left: auto;

    @media ${device.tablet} {
        margin-bottom: 2rem;
    }
`;

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 10px 20px;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;

    @media ${device.tablet} {
        padding: 30px 50px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.medium};
    padding-bottom: 1.2rem;
    margin-bottom: 1rem;
    gap: 10px;
    margin-bottom: 1.2rem;
`;
export const Header = styled.p`
    color: #0b0b0b;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    ${!isSiteName(SITE_NAME.CARSAVER) ? 'font-family: "Corporate E";' : ''}
`;
export const Subheader = styled.p`
    color: #0b0b0b;
    font-size: 12px;
`;

export const FormWrapper = styled.form<{ isLoading: boolean }>`
    width: 100%;
    pointer-events: ${(props) => (props.isLoading ? 'none' : 'revert')};
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'revert')};
    filter: ${(props) => (props.isLoading ? 'grayscale(0.5) blur(2px)' : 'revert')};
`;

export const FormHeader = styled.h3`
    font-size: 14px;
    margin-bottom: 1rem;
`;

export const InputWrapper = styled.div`
    margin-bottom: 1rem;
`;

export const RadioLabel = styled.span`
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-top: 1px;
`;

export const SavingsIndicator = styled.p`
    color: #d64469;
`;

export const ZipInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 515px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    }
`;

export const VoucherHeader = styled.p`
    margin-left: 2rem;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    text-decoration: underline;
`;

export const AnimatedInputWrapper = styled(animated.div)`
    margin-bottom: 1rem;
`;

export const AnimatedContent = styled(animated.div)`
    margin-top: 0.5rem;
`;

export const AgreementsWrapper = styled.div`
    margin-top: 2rem;
`;

export const ModalHeaderActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const BackArrow = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const TermsOverlay = styled.div<{ open: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 10px 20px;
    transition: all 0.4s ease-in-out;
    transform: translateX(100%);
    opacity: 0;
    filter: ${(props) => (props.open ? 'revert' : 'grayscale(0.5) blur(2px)')};
    ${(props) => (props.open ? 'transform: translateX(0);opacity: 1;' : '')};

    @media ${device.tablet} {
        padding: 30px 50px;
    }
`;

export const ReepayTermsModalContent = styled.div`
    padding: 0;
    margin-top: 20px;
    width: auto;

    @media ${device.mobileL} {
        padding: 0 30px;
    }

    & img,
    & p img {
        width: auto;
        max-width: 320px;
        object-fit: cover;

        @media ${device.tablet} {
            max-width: 500px;
        }
    }

    & ul,
    & ol {
        font-size: clamp(14px, 3.3vw - 0.75rem, 1rem);
        font-weight: 500;
        list-style: revert;
        padding: revert;
        margin: revert;
    }
`;

export const StyledApiErrorMessage = styled.p`
    color: #d64469;
    font-weight: 700;
    font-size: 16px;
    margin-top: 15px;
`;
