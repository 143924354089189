import styled from 'styled-components';

export const StyledInfoWindowHeader = styled.p`
    font-size: 22px;
    font-weight: 900;
    font-family: 'Paralucent';
`;

export const StyledInfoWindowDescription = styled.p`
    font-size: 14px;
    margin-top: 16px;
    line-height: 21px;
`;

export const StyledInfoWindowKeyInfoSection = styled.div`
    border-top: 1px solid #e6e6e6;
    margin-top: 24px;
    padding-top: 24px;

    &:last-of-type {
        padding-bottom: 24px;
    }

    a {
        text-decoration: none;
    }
`;

export const StyledInfoWindowKeyInfo = styled.div`
    display: flex;
    gap: 17px;
    align-items: flex-start;
    margin-top: 7px;

    svg {
        margin-top: 6px;
    }

    a {
        line-height: 21px;
        color: #1b1b1b;
        border-bottom: 1px solid #00000033;
        text-decoration: none;
    }

    &:first-of-type {
        margin-top: 0;
        a {
            border-bottom: 1px solid #f7941d;
        }
    }
`;

export const StyledSvgIconContainer = styled.span`
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledReadMoreWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    margin-top: 6px;
    cursor: pointer;
`;

export const StyledReadMoreToggle = styled.p`
    font-size: 14px;
    border-bottom: 1px solid #00000033;
`;

export const StyledOpeningHoursWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: flex-start;

    svg {
        margin-top: 5px;
    }
`;

export const StyledOpeningHoursDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 8px;
`;

export const StyledOpeningHoursDay = styled.p`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    gap: 40px;
`;
