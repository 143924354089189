import styled, { css } from 'styled-components';
import { isSiteName, SITE_NAME } from '../../../../../utils/helpers/site-id.helper';

export const StyledLayoutWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
`;

export const StyledHeaderSection = styled.section`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgb(247, 247, 248, 0.72);
    padding: 15px 15px;
    isolation: isolate;
    z-index: 1000;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    ${isSiteName(SITE_NAME.CARSAVER) &&
    css`
        position: static;
    `}
`;

export const StyledMainContent = styled.div`
    padding: 0 15px;
`;

export const Close = styled.div`
    display: flex;
    align-content: center;
    width: auto;
    align-self: flex-end;
    cursor: pointer;
`;

export const StyledCloseText = styled.span`
    margin-right: 8px;
`;
