import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledContentWrapper = styled.div<{ firstInList: boolean }>`
    padding: ${(props) => (props.firstInList ? '180px' : '70px')} 0 ${(props) => (props.firstInList ? '120px' : '70px')};
    @media ${device.tablet} {
        padding: 210px 0 160px;
    }
`;

export const StyledTeaserText = styled.p`
    font-size: 24px;
    margin-bottom: 16px;
`;

export const StyledFormHeader = styled.p`
    font-size: 18px;
    margin-top: 36px;
    margin-bottom: 24px;
`;

export const StyledFormsContainer = styled.div<{ bgColor: string; textColor: string }>`
    max-width: 900px;
    ${(props) =>
        props.bgColor === 'f7941d' &&
        css`
            a {
                color: #${props.textColor};
            }
        `}
`;
