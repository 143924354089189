import { FC, useState } from 'react';
import { CarSaverWorkshop } from '../../../../../lib/api/organization/carsaver-dealership-api';
import {
    StyledInfoWindowDescription,
    StyledInfoWindowHeader,
    StyledInfoWindowKeyInfo,
    StyledInfoWindowKeyInfoSection,
    StyledOpeningHoursDay,
    StyledOpeningHoursDetails,
    StyledOpeningHoursWrapper,
    StyledReadMoreToggle,
    StyledReadMoreWrapper,
    StyledSvgIconContainer,
} from './carsaver-workshop-info-window.styled';
import { SvgIcon } from '../../../../shared/svg-icon';
import { getGoogleMapsUrl } from '../../../../../utils/helpers/geo-location.helpers';

type IProps = {
    workshop: CarSaverWorkshop;
};

export const CarSaverWorkshopInfoWindow: FC<IProps> = ({ workshop }) => {
    const [readMoreOpen, setReadMoreOpen] = useState(false);
    const shortDescription = workshop.description.substring(0, 140) + '...';

    const getFormattedTime = (time: string) => {
        const split = time.split(':');
        split.pop();
        return split.join(':');
    };

    return (
        <>
            <StyledInfoWindowHeader>{workshop.name}</StyledInfoWindowHeader>
            <StyledInfoWindowDescription>
                {workshop.description.length > 140 && !readMoreOpen ? shortDescription : workshop.description}
            </StyledInfoWindowDescription>
            {workshop.description.length > 140 && !readMoreOpen && (
                <StyledReadMoreWrapper
                    onClick={() => {
                        setReadMoreOpen(true);
                    }}
                >
                    <StyledReadMoreToggle>Læs mere</StyledReadMoreToggle>
                    <SvgIcon iconName="chevron/down" customColor="#1B1B1B" />
                </StyledReadMoreWrapper>
            )}
            <StyledInfoWindowKeyInfoSection>
                <StyledInfoWindowKeyInfo>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/repair" customColor="transparent" />
                    </StyledSvgIconContainer>
                    <a href={workshop.onlineBooking} target="_blank" rel="noreferrer">
                        Book tid på værksted
                    </a>
                </StyledInfoWindowKeyInfo>
                <StyledInfoWindowKeyInfo>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/pin" customColor="transparent" />
                    </StyledSvgIconContainer>
                    <a href={getGoogleMapsUrl(workshop.address, workshop.city).toString()} target="_blank" rel="noreferrer">
                        {workshop.address}, {workshop.city}
                    </a>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/external-link" customColor="transparent" />
                    </StyledSvgIconContainer>
                </StyledInfoWindowKeyInfo>
                <StyledInfoWindowKeyInfo>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/globe" customColor="transparent" />
                    </StyledSvgIconContainer>
                    <a href={workshop.website} target="_blank" rel="noreferrer">
                        {workshop.website}
                    </a>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/external-link" customColor="transparent" />
                    </StyledSvgIconContainer>
                </StyledInfoWindowKeyInfo>
                <StyledInfoWindowKeyInfo>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/mail" customColor="transparent" />
                    </StyledSvgIconContainer>
                    <a href={`mailto:${workshop.email}`}>{workshop.email}</a>
                </StyledInfoWindowKeyInfo>
                <StyledInfoWindowKeyInfo>
                    <StyledSvgIconContainer>
                        <SvgIcon iconName="carsaver/phone" customColor="transparent" />
                    </StyledSvgIconContainer>
                    <a href={`tel:${workshop.phone}`}>{workshop.phone}</a>
                </StyledInfoWindowKeyInfo>
            </StyledInfoWindowKeyInfoSection>
            <StyledInfoWindowKeyInfoSection>
                <StyledOpeningHoursWrapper>
                    <SvgIcon iconName="carsaver/clock" customColor="transparent" />
                    <StyledOpeningHoursDetails>
                        <StyledOpeningHoursDay>
                            <strong>Mandag</strong>
                            <span>
                                {workshop.openingHours.monday !== null
                                    ? `${getFormattedTime(workshop.openingHours.monday.from)}-${getFormattedTime(workshop.openingHours.monday.to)}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Tirsdag</strong>
                            <span>
                                {workshop.openingHours.tuesday !== null
                                    ? `${getFormattedTime(workshop.openingHours.tuesday.from)}-${getFormattedTime(workshop.openingHours.tuesday.to)}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Onsdag</strong>
                            <span>
                                {workshop.openingHours.wednesday !== null
                                    ? `${getFormattedTime(workshop.openingHours.wednesday.from)}-${getFormattedTime(
                                          workshop.openingHours.wednesday.to
                                      )}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Torsdag</strong>
                            <span>
                                {workshop.openingHours.thursday !== null
                                    ? `${getFormattedTime(workshop.openingHours.thursday.from)}-${getFormattedTime(
                                          workshop.openingHours.thursday.to
                                      )}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Fredag</strong>
                            <span>
                                {workshop.openingHours.friday !== null
                                    ? `${getFormattedTime(workshop.openingHours.friday.from)}-${getFormattedTime(workshop.openingHours.friday.to)}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Lørdag</strong>
                            <span>
                                {workshop.openingHours.saturday !== null
                                    ? `${getFormattedTime(workshop.openingHours.saturday.from)}-${getFormattedTime(
                                          workshop.openingHours.saturday.to
                                      )}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                        <StyledOpeningHoursDay>
                            <strong>Søndag</strong>
                            <span>
                                {workshop.openingHours.sunday !== null
                                    ? `${getFormattedTime(workshop.openingHours.sunday.from)}-${getFormattedTime(workshop.openingHours.sunday.to)}`
                                    : 'Lukket'}
                            </span>
                        </StyledOpeningHoursDay>
                    </StyledOpeningHoursDetails>
                </StyledOpeningHoursWrapper>
            </StyledInfoWindowKeyInfoSection>
        </>
    );
};
