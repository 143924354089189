import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { Abbreviations, Units } from '../../../constants/units-and-abbreviations';
import { SearchStoreContext } from '../../../lib/state/search/search.store';
import { getImageCropUrl, formatPrice, sanitizeMarkup } from '../../../utils/helpers';
import {
    StyledSearchResult,
    StyledSearchResultImageContainer,
    StyledSearchResultImage,
    StyledSearchResultInformationContainer,
    StyledVehicleNameAndFamilyInfo,
    StyledBrandModelText,
    StyledVehicleName,
    StyledColorContainer,
    StyledColorPreview,
    StyledVehiclePricesContainer,
    StyledPriceAndLabelContainer,
    StyledPriceLabel,
    StyledPriceValue,
    StyledDesktopOnlyWrapper,
    StyledSearchResultWrapper,
    StyledLoadMoreButonContainer,
    StyledFinancePrice,
    StyledAvailabilityTile,
    StyledSpecsContainter,
    StyledSpecsRow,
    StyledSpecsValue,
} from './search-results.styled';
import { getParentColorAssets } from '../../../lib/api/vehicle/hessel-vehicle-plp-api';
import { ParentColor } from '../../../lib/api/models/hessel-api/product-list.types';
import { Button } from '../../shared';
import { ToolTipContent } from '../../shared/tool-tip-content/tool-tip-content.component';
import { useSegmentContext } from '../../../hooks/use-segment-context';
import { ProductResult } from '@relewise/client';

export const VehicleSearchResults: FC = () => {
    const { searchVehicleResults, vehicleSearchTotalCount, searchTerm, currentPage } = SearchStoreContext.useStoreState((state) => state);
    const { getNextPageVehicleResults } = SearchStoreContext.useStoreActions((actions) => actions);

    const segment = useSegmentContext();

    const [parentColorAssets, setParentColorAssets] = useState<ParentColor[]>([]);
    useEffect(() => {
        const getParentColors = async () => {
            const [result, error] = await getParentColorAssets();
            if (result && !error) {
                setParentColorAssets(result);
            }
        };
        if (parentColorAssets.length === 0 && searchVehicleResults.length > 0) {
            getParentColors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVehicleResults]);

    const getAvailabilityText = (availabilityCode: string) => {
        switch (availabilityCode) {
            case 'o':
                return 'Bestillingsbil';
            case 'u':
                return 'Brugt';
            case 'e':
                return 'Engros';
            case 's':
                return 'Ny på lager';
            default:
                return '';
        }
    };

    const getKeySpecs = (vehicle: ProductResult): Array<{ key: string; value: string }> => {
        if (!vehicle.data) return [];
        const fuelType = {
            key: 'Drivmiddel',
            value: vehicle.data.FuelType?.value ?? '-',
        };
        const consumption = {
            key: 'Forbrug',
            value: vehicle.data.MixedDriving
                ? `${vehicle.data.MixedDriving.value} ${
                      vehicle.data.MixedDrivingValue && (vehicle.data.MixedDrivingValue.value as string).toLowerCase() === 'km/l'
                          ? Abbreviations.KM_SLASH_L
                          : vehicle.data.MixedDrivingValue.value
                  }`
                : '-',
        };
        const horsePower = {
            key: 'Hestekræfter',
            value: vehicle.data.HorsePower ? `${vehicle.data.HorsePower.value} ${Units.HP}` : '-',
        };
        const modelYear = {
            key: 'Årgang',
            value: vehicle.data.ModelYear?.value ?? '-',
        };
        const odometerReading = {
            key: 'Kilometerstand',
            value: vehicle.data.OdometerReadingInKm ? `${formatPrice(Number(vehicle.data.OdometerReadingInKm.value))} ${Units.km}` : '-',
        };
        const towingWeight = {
            key: 'Trækvægt',
            value: vehicle.data.TowingWeight ? `${formatPrice(Number(vehicle.data.TowingWeight.value))} ${Units.KG}` : '-',
        };
        if (vehicle.data.AvailabilityCode.value === 'u') {
            return [modelYear, fuelType, odometerReading];
        }
        if (
            vehicle.data.AvailabilityCode.value === 's' ||
            (vehicle.data.AvailabilityCode.value === 'o' && vehicle.data.CategoryTypeCode.value === 'c')
        ) {
            return [fuelType, consumption, horsePower];
        }
        if (vehicle.data.CategoryTypeCode.value === 'v' && vehicle.data.AvailabilityCode.value === 'o') {
            return [fuelType, consumption, towingWeight];
        }
        return [];
    };

    if (searchVehicleResults.length === 0) return null;

    return (
        <StyledSearchResultWrapper>
            {searchVehicleResults.map((result) => {
                if (result.data) {
                    const vehicleName = () => {
                        if (
                            result.data &&
                            result.data.Name &&
                            result.data.ModelTitle &&
                            (result.data.Name.value as string).startsWith(result.data.ModelTitle.value as string)
                        ) {
                            return (result.data.Name.value as string).split(result.data.ModelTitle.value as string)[1].trim();
                        } else if (result.data && result.data.Name) {
                            return result.data.Name.value as string;
                        }
                        return '';
                    };
                    return (
                        <div key={result.productId}>
                            <Link href={result.data.Url.value}>
                                <StyledSearchResult href={result.data.Url.value}>
                                    <StyledSearchResultImageContainer>
                                        <StyledSearchResultImage src={result.data.ImageUrl.value} alt={result.productId ?? ''} />
                                    </StyledSearchResultImageContainer>
                                    <StyledSearchResultInformationContainer>
                                        <StyledVehicleNameAndFamilyInfo>
                                            {result.brand && (
                                                <StyledBrandModelText>{`${result.brand.displayName}${
                                                    result.data.ModelTitle?.value ? `, ${result.data.ModelTitle.value}` : ''
                                                }`}</StyledBrandModelText>
                                            )}
                                            <StyledVehicleName>{vehicleName()}</StyledVehicleName>
                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                {result.data.AvailabilityCode && (
                                                    <StyledAvailabilityTile availability={result.data.AvailabilityCode.value}>
                                                        {getAvailabilityText(result.data.AvailabilityCode.value)}
                                                    </StyledAvailabilityTile>
                                                )}
                                                {result.data.ParentColors &&
                                                result.data.ParentColors.value &&
                                                result.data.ParentColors.value.$values &&
                                                Array.isArray(result.data.ParentColors.value.$values) ? (
                                                    <StyledColorContainer>
                                                        {result.data.ParentColors.value.$values.map((color: string, index: number) => {
                                                            const parentColor = parentColorAssets.find((pc) => pc.name === color);
                                                            if (!parentColor) return null;
                                                            return (
                                                                <StyledColorPreview
                                                                    key={index}
                                                                    style={{
                                                                        backgroundImage: `url('${getImageCropUrl(
                                                                            parentColor.resource.data,
                                                                            'icon'
                                                                        )}')`,
                                                                    }}
                                                                ></StyledColorPreview>
                                                            );
                                                        })}
                                                    </StyledColorContainer>
                                                ) : null}
                                            </div>
                                        </StyledVehicleNameAndFamilyInfo>
                                        <StyledSpecsContainter>
                                            <StyledSpecsRow>
                                                {getKeySpecs(result).map((spec, idx) => {
                                                    return <p key={idx}>{spec.key}</p>;
                                                })}
                                            </StyledSpecsRow>
                                            <StyledSpecsRow>
                                                {getKeySpecs(result).map((spec, idx) => {
                                                    return (
                                                        <p key={idx}>
                                                            <StyledSpecsValue>{spec.value}</StyledSpecsValue>
                                                        </p>
                                                    );
                                                })}
                                            </StyledSpecsRow>
                                        </StyledSpecsContainter>
                                        <StyledVehiclePricesContainer>
                                            {result.salesPrice ? (
                                                <StyledPriceAndLabelContainer>
                                                    <StyledPriceLabel>Kontant</StyledPriceLabel>
                                                    <StyledPriceValue>{`${formatPrice(result.salesPrice)} ${Abbreviations.KR}`}</StyledPriceValue>
                                                </StyledPriceAndLabelContainer>
                                            ) : null}
                                            {result.data.BaseMonthlyPrice ? (
                                                <StyledDesktopOnlyWrapper>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <StyledPriceAndLabelContainer>
                                                            <StyledPriceLabel>Finansiering</StyledPriceLabel>
                                                            <StyledFinancePrice>{`${formatPrice(result.data.BaseMonthlyPrice?.value.amount)} ${
                                                                Abbreviations.KR_SLASH_MD
                                                            }`}</StyledFinancePrice>
                                                        </StyledPriceAndLabelContainer>
                                                    </div>
                                                </StyledDesktopOnlyWrapper>
                                            ) : null}
                                            {result.data.DownPayment ? (
                                                <StyledDesktopOnlyWrapper>
                                                    <StyledPriceLabel style={{ marginRight: '5px', marginTop: '4px' }}>
                                                        {`Udbetaling ${formatPrice(result.data.DownPayment.value.amount)} ${Abbreviations.KR}`}
                                                        <div style={{ marginBottom: '-2px', marginLeft: '2px' }}>
                                                            <ToolTipContent positionIsFixed={true}>
                                                                <span {...sanitizeMarkup(result.data.SantanderComplianceText.value)}></span>
                                                            </ToolTipContent>
                                                        </div>
                                                    </StyledPriceLabel>
                                                </StyledDesktopOnlyWrapper>
                                            ) : null}
                                        </StyledVehiclePricesContainer>
                                    </StyledSearchResultInformationContainer>
                                </StyledSearchResult>
                            </Link>
                        </div>
                    );
                }
                return null;
            })}
            {vehicleSearchTotalCount > searchVehicleResults.length && (
                <StyledLoadMoreButonContainer>
                    <Button variant="dark" onClick={() => getNextPageVehicleResults({ searchTerm, page: currentPage + 1, segment })}>
                        Indlæs flere
                    </Button>
                </StyledLoadMoreButonContainer>
            )}
        </StyledSearchResultWrapper>
    );
};
