import { FC, useEffect, useState } from 'react';
import { theme } from '../../../../themes/default-theme';
import { CarSaverWorkshop } from '../../../../lib/api/organization/carsaver-dealership-api';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import ReactDOM from 'react-dom';
import { StyledInfoWindow } from './carsaver-map-markers.styled';
import { CarSaverWorkshopInfoWindow } from './carsaver-workshop-info-window/carsaver-workshop-info-window.component';

type IProps = {
    map: google.maps.MarkerOptions;
    workshops: CarSaverWorkshop[];
};

export const CarSaverMapMarkers: FC<IProps> = ({ map, workshops }) => {
    const [markersLoaded, setMarkersLoaded] = useState(false);
    useEffect(() => {
        if (map !== null && map.map && !markersLoaded) {
            setMarkersLoaded(true);
            const markers: google.maps.Marker[] = [];
            const infoWindow = new google.maps.InfoWindow({
                content: '',
                disableAutoPan: true,
                pixelOffset: new google.maps.Size(0, -15),
            });
            workshops.forEach((workshop) => {
                const marker = new google.maps.Marker({
                    label: {
                        text: ' ',
                        color: 'white',
                        fontFamily: theme.fontFamily.secondary,
                        fontSize: '15px',
                        fontWeight: '500',
                    },
                    icon: {
                        url: `/png/carsaver-workshop-pin.png`,
                        labelOrigin: new google.maps.Point(0, 0),
                        scaledSize: new google.maps.Size(34, 47),
                    },
                    position: {
                        lat: workshop.latitude,
                        lng: workshop.longitude,
                    },
                });
                marker.addListener('click', () => {
                    const el = document.createElement('div');
                    el.id = 'google-maps-dealership-popup';
                    ReactDOM.render(
                        <StyledInfoWindow>
                            <CarSaverWorkshopInfoWindow workshop={workshop} />
                        </StyledInfoWindow>,
                        el
                    );

                    const markerPosition = marker.getPosition();
                    if (markerPosition && map.map) {
                        (map.map as google.maps.Map).setCenter(markerPosition);
                        (map.map as google.maps.Map).panBy(0, -200);
                    }

                    infoWindow?.setContent(el);
                    infoWindow?.open({
                        anchor: marker,
                        map: map.map as google.maps.Map,
                        shouldFocus: false,
                    });
                });
                markers.push(marker);
            });
            if (map.map) {
                const renderer = {
                    render: (cluster: Cluster) =>
                        new google.maps.Marker({
                            label: { text: String(cluster.count), color: 'black', fontSize: '16px', fontFamily: 'Paralucent', fontWeight: '700' },
                            position: cluster.position,
                            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
                            icon: '/png/carsaver-map-pin-cluster.png',
                        }),
                };
                new MarkerClusterer({ map: map.map as google.maps.Map, markers, renderer });
            }
        }
    }, [map, markersLoaded, workshops]);

    return null;
};
