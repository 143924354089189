import { VFC, useMemo } from 'react';
import { Accordion } from '../../accordion';

import {
    CustomerInfoMobile,
    StyledFooter,
    InfoSection,
    Content,
    OpeningHoursWrapper,
    OpeningHoursContent,
    MasterData,
    PaymentWrapper,
    CreditsWrapper,
    CreditCard,
    CtaHandlerWrapperMobile,
} from './footer-mobile.styled';

import Link from 'next/link';
import {
    SmallText,
    TextGroup,
    StyledBrand,
    SmallLink,
    OpeningHoursTitle,
    StyledFooterLogo,
    StyledFooterShape,
    SmallLinkWrapper,
    CarSaverTextGroup,
    CarSaverSmallLink,
    StyledBackgroundColorFill,
} from '../footer.styled';
import { useAccordionController } from '../../../../hooks/use-accordion-controller';
import { umbraco } from '../../../../lib/api';
import { CtaHandler } from '../../../plus-sites-shared/content-handlers';
import { MEDIA_URL } from '../../../../utils/environment-constants';
import { MenuContext } from '../../../mega-menu/mega-menu.component';
import { FooterShape } from '../footer-shape.component';
import { SITE_NAME, isSiteName } from '../../../../utils/helpers/site-id.helper';
import { StyledSocialItem, StyledSocialList, StyledSocialMediaWrapper } from '../footer.styled';
import { StyledCtaHandler } from '../desktop/footer-desktop.styled';

type IProps = umbraco.FooterContent & {
    menuContext?: MenuContext;
    backgroundColor?: string;
};

export const FooterMobile: VFC<IProps> = ({
    footerLogo,
    companyName,
    companyAddress,
    companyEmail,
    cvr,
    telephone,
    columns,
    erhvervColumns,
    openingHours = [],
    paymentIcons,
    menuContext,
    socialMediaHeader,
    socialMediaLinks,
    backgroundColor,
}) => {
    const { registerAccordion } = useAccordionController();

    const columnsToRender = useMemo(() => {
        if (menuContext === 'erhverv') return erhvervColumns;
        return columns;
    }, [columns, erhvervColumns, menuContext]);

    return (
        <StyledFooter carsaver={isSiteName(SITE_NAME.CARSAVER)} marginTop={backgroundColor === 'f7941d' ? '-90px' : undefined}>
            {isSiteName(SITE_NAME.CARSAVER) && backgroundColor !== 'f7941d' && (
                <>
                    <StyledBackgroundColorFill bgColor={`#${backgroundColor}` ?? 'transparent'} />
                    <StyledFooterShape bgColor={`#${backgroundColor}` ?? 'transparent'}>
                        <FooterShape></FooterShape>
                    </StyledFooterShape>
                </>
            )}
            {!isSiteName(SITE_NAME.CARSAVER) && (
                <InfoSection>
                    {columnsToRender.map((x, index) => {
                        return (
                            <Content key={`footer-mobile-col-${x.title}-${index}`}>
                                <Accordion title={x.title} headerSize="md" border="bottom" {...registerAccordion(index)}>
                                    <CtaHandlerWrapperMobile>
                                        <CtaHandler ctas={x.links} pageSection="Footer" />
                                    </CtaHandlerWrapperMobile>
                                </Accordion>
                            </Content>
                        );
                    })}
                </InfoSection>
            )}

            {footerLogo ? (
                <StyledFooterLogo
                    maxWidth={isSiteName(SITE_NAME.CARSAVER) ? '158px' : '250px'}
                    src={MEDIA_URL + footerLogo.src}
                    alt={footerLogo.name}
                    style={isSiteName(SITE_NAME.CARSAVER) ? { marginBottom: '58px' } : {}}
                />
            ) : (
                !isSiteName(SITE_NAME.CARSAVER) && <StyledBrand>Ejner Hessel</StyledBrand>
            )}

            <CustomerInfoMobile>
                <MasterData>
                    {isSiteName(SITE_NAME.CARSAVER) ? (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <CarSaverTextGroup>
                                <SmallText boldText style={{ fontSize: '18px', marginBottom: '17px' }}>
                                    {companyAddress}
                                </SmallText>
                            </CarSaverTextGroup>

                            <CarSaverTextGroup>
                                <SmallText boldText>CVR nr.:</SmallText>
                                <SmallText>{cvr}</SmallText>
                            </CarSaverTextGroup>

                            <CarSaverTextGroup>
                                <SmallText boldText>Tlf. nr.:</SmallText>
                                <Link href={`tel:${telephone}`} passHref={true}>
                                    <CarSaverSmallLink>{telephone}</CarSaverSmallLink>
                                </Link>
                            </CarSaverTextGroup>

                            <CarSaverTextGroup>
                                <SmallText boldText>E-mail:</SmallText>
                                <Link href={`mailto:${companyEmail}`} passHref={true}>
                                    <CarSaverSmallLink>{companyEmail}</CarSaverSmallLink>
                                </Link>
                            </CarSaverTextGroup>

                            {columnsToRender.map((x, index) => {
                                return (
                                    <CarSaverTextGroup key={`footer-desktop-col-${x.title}-${index}`}>
                                        <SmallText boldText>{x.title}:</SmallText>
                                        <SmallLinkWrapper>
                                            <StyledCtaHandler ctas={x.links} fontSize="medium" pageSection="Footer" />
                                        </SmallLinkWrapper>
                                    </CarSaverTextGroup>
                                );
                            })}
                        </div>
                    ) : (
                        <div>
                            <TextGroup>
                                <p>{companyName}</p>
                            </TextGroup>
                            <TextGroup>
                                <p>{companyAddress}</p>
                            </TextGroup>

                            <TextGroup>
                                <SmallText>CVR nr.: {cvr}</SmallText>
                            </TextGroup>

                            <TextGroup>
                                <p>Tlf. nr.:</p>
                                <Link href={`tel:${telephone}`} passHref={true}>
                                    <SmallLink>{telephone}</SmallLink>
                                </Link>
                            </TextGroup>

                            <TextGroup>
                                <p>E-mail:</p>
                                <Link href={`mailto:${companyEmail}`} passHref={true}>
                                    <SmallLink>{companyEmail}</SmallLink>
                                </Link>
                            </TextGroup>
                        </div>
                    )}
                </MasterData>

                <OpeningHoursWrapper style={isSiteName(SITE_NAME.CARSAVER) ? { margin: '15px 0 24px' } : {}}>
                    {openingHours && openingHours.length > 0 ? (
                        <OpeningHoursTitle>{isSiteName(SITE_NAME.CARSAVER) ? 'Kundeservice:' : 'Åbningstider:'}</OpeningHoursTitle>
                    ) : null}
                    {openingHours.map((x, index) => (
                        <OpeningHoursContent key={`${companyName}-${index}`} isCarSaver={isSiteName(SITE_NAME.CARSAVER)}>
                            <SmallText>{x.days}</SmallText>
                            {x.alias === 'openingHour' && <SmallText showAsItalic={!x.isOpen}>{x.hours}</SmallText>}
                            {x.alias === 'openingHourLink' && (
                                <Link href={x.ctaUrl.url}>
                                    <SmallLink>{x.ctaUrl.name}</SmallLink>
                                </Link>
                            )}
                        </OpeningHoursContent>
                    ))}
                </OpeningHoursWrapper>

                {isSiteName(SITE_NAME.CARSAVER) && socialMediaLinks && (
                    <StyledSocialMediaWrapper>
                        {socialMediaHeader && (
                            <p style={{ marginBottom: '8px' }}>
                                <strong>{socialMediaHeader}</strong>
                            </p>
                        )}
                        <StyledSocialList>
                            {socialMediaLinks.map((x, index) => {
                                return (
                                    <StyledSocialItem key={index}>
                                        <Link href={x.mediaLink.url}>
                                            <a href={x.mediaLink.url}>
                                                {x.mediaIcon && x.mediaIcon.src ? (
                                                    <img src={MEDIA_URL + x.mediaIcon.src} alt={x.mediaName} />
                                                ) : (
                                                    <SmallLink>{x.mediaName}</SmallLink>
                                                )}
                                            </a>
                                        </Link>
                                    </StyledSocialItem>
                                );
                            })}
                        </StyledSocialList>
                    </StyledSocialMediaWrapper>
                )}

                {paymentIcons && paymentIcons.length > 0 ? (
                    <PaymentWrapper>
                        {isSiteName(SITE_NAME.CARSAVER) ? <strong style={{ marginBottom: '2px' }}>Sikker betaling</strong> : <p>Sikker betaling</p>}
                        <CreditsWrapper>
                            {paymentIcons.map((x, index) => {
                                return (
                                    <CreditCard key={`footer-mobile-payment-${index}`}>
                                        <img src={`${MEDIA_URL}/${x.src}`} alt={x.name} loading="lazy" />
                                    </CreditCard>
                                );
                            })}
                        </CreditsWrapper>
                    </PaymentWrapper>
                ) : null}
            </CustomerInfoMobile>
        </StyledFooter>
    );
};
