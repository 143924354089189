import { FC, useMemo } from 'react';
import {
    StyledText,
    StyledImage,
    StyledName,
    StyledShopProductCard,
    StyledFooter,
    StyledPriceNowAndThen,
    StyledPriceNow,
    ImageAndBadgeWrapper,
    StyledProductInformationWrapper,
    StyledPriceThen,
    StyledBadgeWrapper,
} from './shop-product-card.styled';
import { ShopProductDetails } from '../../../lib/api/models/shop';
import { getShopPrice } from '../../../utils/helpers';
import { StockIndicator } from '../stock-indicator/stock-indicator.component';
import { PromotionBadges } from '../promotion-badges/promotion-badge.component';
import { ProductResult } from '@relewise/client';
import { getProductAttributes } from './shop-product-card-mapper';

type ShopProductCardProps = {
    className?: string;
    product: ShopProductDetails | ProductResult;
};

export const ShopProductCard: FC<ShopProductCardProps> = ({ className, product }: ShopProductCardProps) => {
    const productAttributes = useMemo(() => getProductAttributes(product), [product]);

    return (
        <StyledShopProductCard className={className}>
            <ImageAndBadgeWrapper>
                <StyledImage src={productAttributes.imageUrl} alt={productAttributes.name} />
                {productAttributes.promotionIds && productAttributes.promotionIds.length > 0 ? (
                    <StyledBadgeWrapper>
                        <PromotionBadges promotions={productAttributes.promotionIds} />
                    </StyledBadgeWrapper>
                ) : null}
            </ImageAndBadgeWrapper>
            <StyledProductInformationWrapper>
                <StyledName>{productAttributes.name}</StyledName>
                {productAttributes.shortDescription && productAttributes.shortDescription.length > 0 ? (
                    <StyledText>{`${productAttributes.shortDescription.substring(0, 140)}${
                        productAttributes.shortDescription.length > 140 ? '...' : ''
                    }`}</StyledText>
                ) : null}
                <StyledFooter>
                    <StockIndicator inventory={1} stockStatus={productAttributes.stockStatus} />
                    <StyledPriceNowAndThen>
                        {productAttributes.originalPrice !== productAttributes.price ? (
                            <>
                                <StyledPriceThen>{getShopPrice(productAttributes.originalPrice)}</StyledPriceThen>
                                <StyledPriceNow color="primary">{getShopPrice(productAttributes.price)}</StyledPriceNow>
                            </>
                        ) : (
                            <StyledPriceNow color="default">{getShopPrice(productAttributes.price)}</StyledPriceNow>
                        )}
                    </StyledPriceNowAndThen>
                </StyledFooter>
            </StyledProductInformationWrapper>
        </StyledShopProductCard>
    );
};
