import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useCarouselControls } from '../../../../hooks/use-carousel-controls';
import { hesselViewModels } from '../../../../lib/view-models';
import { next } from '../../../../utils/helpers/incremental-integer.helper';
import { mapSlides } from '../carousel-utils';

import { Image } from '../../../../lib/view-models/vehicle';
import {
    StyledInfoText,
    StyledInfoTextWrapper,
    StyledSimpleCarousel,
    StyledSimpleCarouselControls,
    StyledSimpleCarouselNextButton,
    StyledSimpleCarouselPreviousButton,
    StyledSimpleCarouselZoomControl,
    StyledSimpleInnerCarousel,
    StyledSimpleInnerCarouselCaption,
    StyledSimpleInnerCarouselImageCounter,
    StyledSimpleInnerCarouselImageThumbnail,
    StyledSimpleInnerCarouselImageThumbnailWrapper,
} from './simple-carousel.styled';

export type SimpleCarouselProps = {
    renderControls?: (activeIndex: number) => ReactNode;
    className?: string;
    imageAspectRatio?: string;
    slides: hesselViewModels.PdpResource[];
    resetCarousel?: boolean;
    onCarouselReset?: () => void;
    showImageAsCover?: boolean;
    isAboveFold: boolean;
    useSquaredCorners?: boolean;
    hideArrowControls?: boolean;
    onThumbnailClick?: (index: number) => void;
    infoText?: string;
    blendMode?: {
        mixBlendMode: string;
        backgroundColor: string;
    };
    containThumbnailImages?: boolean;
};

export const SimpleCarousel: FC<SimpleCarouselProps> = (props) => {
    const { activeIndex, bindGestures, dragOffset, nextSlide, onKeyDown, previousSlide, setActiveIndex } = useCarouselControls(props.slides.length);
    const [carouselDomId] = useState(`simple-carousel-${next()}`);
    const carouselItems = useMemo(() => mapSlides(props.slides, carouselDomId), [carouselDomId, props.slides]);
    const containsPhyronVideo = useMemo(() => props.slides.some((x) => x.type === 'phyron'), [props.slides]);

    const thumbImages = useMemo(() => {
        const images = props.slides.filter((x) => x.type === 'image');
        return (containsPhyronVideo ? images.slice(0, 4) : images.slice(1, 5)) as Image[];
    }, [containsPhyronVideo, props.slides]);
    useEffect(() => {
        if (props.resetCarousel) {
            setActiveIndex(0);
            props.onCarouselReset?.();
        }
    }, [props, props.resetCarousel, setActiveIndex]);

    const imagesCountAboveThumbs = useMemo(() => {
        return props.slides.length - thumbImages.length - 1;
    }, [props.slides.length, thumbImages.length]);

    return props.slides ? (
        <>
            <StyledSimpleCarousel aria-roledescription="carousel" className={props.className} role="region">
                <StyledSimpleCarouselZoomControl>
                    {props.renderControls &&
                    carouselItems.length > 0 &&
                    (carouselItems[activeIndex].slide.type === 'image' || carouselItems[activeIndex].slide.type === 'phyron')
                        ? props.renderControls(activeIndex)
                        : null}
                </StyledSimpleCarouselZoomControl>
                {!props.hideArrowControls && (
                    <StyledSimpleCarouselControls onKeyDown={(e) => onKeyDown(e)}>
                        {props.slides.length > 1 ? <StyledSimpleCarouselPreviousButton label={'Forrige'} onClick={previousSlide} /> : <div />}
                        {props.slides.length > 1 ? <StyledSimpleCarouselNextButton label={'Næste'} onClick={nextSlide} /> : <div />}
                    </StyledSimpleCarouselControls>
                )}
                <StyledSimpleInnerCarousel
                    activeIndex={activeIndex}
                    aspectRatio={props.imageAspectRatio}
                    dragOffset={dragOffset}
                    id={carouselDomId}
                    items={carouselItems}
                    rootNodeAttrs={bindGestures}
                    renderCaption={(slide) => {
                        if (slide.type === 'youTube') {
                            return null;
                        }

                        return slide.caption && <StyledSimpleInnerCarouselCaption>{slide.caption}</StyledSimpleInnerCarouselCaption>;
                    }}
                    showImageAsCover={props.showImageAsCover}
                    isAboveFold={props.isAboveFold}
                    useSquaredCorners={props.useSquaredCorners}
                    blendMode={props.blendMode}
                />
                {props.infoText && (
                    <StyledInfoTextWrapper>
                        <StyledInfoText>{props.infoText}</StyledInfoText>
                    </StyledInfoTextWrapper>
                )}
            </StyledSimpleCarousel>
            <StyledSimpleInnerCarouselImageThumbnailWrapper imageContain={props.containThumbnailImages}>
                {thumbImages.map((img, index) => (
                    <StyledSimpleInnerCarouselImageThumbnail key={index}>
                        <img
                            src={img?.url}
                            key={index}
                            alt={img.caption}
                            onClick={() => {
                                props.onThumbnailClick?.(index + 1);
                            }}
                            style={{ cursor: 'pointer', mixBlendMode: 'multiply' }}
                        />
                        {index === thumbImages.length - 1 && imagesCountAboveThumbs > 1 && (
                            <StyledSimpleInnerCarouselImageCounter
                                onClick={() => {
                                    props.onThumbnailClick?.(index + 1);
                                }}
                            >
                                + {imagesCountAboveThumbs}
                            </StyledSimpleInnerCarouselImageCounter>
                        )}
                    </StyledSimpleInnerCarouselImageThumbnail>
                ))}
            </StyledSimpleInnerCarouselImageThumbnailWrapper>
        </>
    ) : null;
};
